import React from 'react'
import { Link } from 'react-router-dom'


const Wrinkles_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Wrinkles Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Wrinkles Treatment</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        Welcome to Netis Skin and Hair Clinic, where we specialize in helping you achieve smoother, more youthful-looking skin. Under the expert care of Dr. Sai Prasanth Neti, our clinic offers state-of-the-art wrinkle treatments. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to diminish the appearance of wrinkles.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Wrinkle Treatment?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                    
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Wrinkles Treatment.jpeg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Customized Approach: </b>Recognizing that each individual's skin is unique, we create personalized treatment plans to address your specific concerns. Our goal is to reveal a more youthful, smoother complexion tailored to your skin's needs.</p>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our wrinkle treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Wrinkle Treatments</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Wrinkle treatments at Netis Skin and Hair Clinic encompass a range of advanced procedures designed to address lines, folds, and sagging skin. These may include:</p>
                        <p style={{ textAlign: 'justify' }}><b>Dermal Fillers: </b>Injectable fillers are used to plump up and fill in wrinkles, restoring volume and smoothness to the skin.</p>
                        <p style={{ textAlign: 'justify' }}><b>Botox® and Dysport®: </b>These neuromodulators temporarily relax the muscles that cause wrinkles, resulting in a smoother, more youthful appearance.</p>
                        <p style={{ textAlign: 'justify' }}><b>Laser Resurfacing: </b>Advanced laser technology is used to stimulate collagen production, improving skin texture and reducing the appearance of wrinkles.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}> Key Benefits of Wrinkle Treatment:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Smoother, Youthful Skin: </b>Diminish the appearance of fine lines and wrinkles for a more youthful complexion.</p>
                        <p style={{ textAlign: 'justify' }}><b>Restored Volume: </b>Fillers can add volume to areas that have lost elasticity, providing a more youthful and rejuvenated look.</p>
                        <p style={{ textAlign: 'justify' }}><b>Long-Lasting Results: </b>Depending on the treatment, results can last for several months to years.</p>
 
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Youthful, Radiant Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                        <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, wrinkle-free complexion you deserve.</p>

 
            </div>

        </>
    )
}

export default Wrinkles_Treatment