import React from 'react'
import { Link } from 'react-router-dom'


const HyperPigmentation = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>HyperPigmentation</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>HyperPigmentation</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>

                        Hyperpigmentation is a common skin condition characterized by the darkening or increased pigmentation of certain areas of the skin. This occurs when there is an excess production of melanin, the pigment responsible for the color of the skin, hair, and eyes. Melanin is produced by melanocytes, specialized cells located in the epidermis (the outermost layer of the skin).
                        </p>
                       

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>There are several factors that can contribute to hyperpigmentation:</span>
                        </h4 >

                        <p><b>Sun Exposure: </b>UV rays from the sun stimulate the production of melanin as a defense mechanism to protect the skin from the harmful effects of sun exposure.</p>
                        <p><b>Inflammation: </b>Skin inflammation resulting from acne, wounds, or other skin conditions can trigger an overproduction of melanin in the affected areas, leading to post-inflammatory hyperpigmentation (PIH).</p>
                        <p><b>Hormonal Changes: </b>Pregnancy, birth control pills, and hormonal fluctuations associated with conditions like melasma can cause hyperpigmentation.</p>
                        <p><b>Age: </b>With aging, the skin may develop dark spots or age spots due to cumulative sun exposure over the years.</p>
                        <p><b>Genetics: </b>Some individuals may be more prone to developing hyperpigmentation due to genetic factors.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='HyperPigmentationImage.png' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Common types of hyperpigmentation include:</span>
                        </h4 >
                
                <p><b>Post-Inflammatory Hyperpigmentation (PIH): </b>Dark spots that develop after an inflammatory skin condition or injury has healed.</p>
                <p><b>Melasma: </b>Brown or gray-brown patches, often occurring on the face, associated with hormonal changes.</p>
                <p><b>Sunspots or Solar Lentigines: </b>Dark spots that develop due to prolonged exposure to sunlight.</p>
                <p>Hyperpigmentation is generally harmless and does not pose a health risk. However, it can be a cosmetic concern for many individuals, affecting their self-esteem and confidence. Preventive measures include sun protection (using sunscreen and protective clothing) and the use of skincare products containing ingredients that inhibit melanin production. Treatment options may include topical creams, laser therapy, chemical peels, or other dermatological procedures, depending on the type and severity of hyperpigmentation.</p>






 
               </div>

        </>
    )
}

export default HyperPigmentation