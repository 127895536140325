import React from 'react'
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon 
  } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    
    <MDBFooter bgColor='light' className='container-fluid text-center text-lg-start text-muted mt-5 mb-2'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' style={{backgroundColor:'#1f1f81',color:'snow'}}>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div style={{color:'whitesmoke'}}>
          <a href='https://www.facebook.com/drnetisskinhairandnailclinic/' className='me-4 text-reset' style={{color:'whitesmoke'}}>
            <MDBIcon  fab icon="bi bi-facebook"  />
            
          </a>
          <a href='/' className='me-4 text-reset'>
            <MDBIcon  fab icon='bi bi-twitter' />
          </a>
          <a href='https://www.google.com/search?q=dr+neti%27s+SKIN+HAIR+AND+NAIL+CLINIC&sca_esv=571500735&rlz=1C1JJTC_enIN1028IN1028&sxsrf=AM9HkKm9A7Ld4K9eL8l7FeS_EBoNk-oFQQ%3A1696658204402&ei=HPMgZcKOGOqaseMP2ZaS2AU&ved=0ahUKEwjCjNCsoOOBAxVqTWwGHVmLBFsQ4dUDCBA&uact=5&oq=dr+neti%27s+SKIN+HAIR+AND+NAIL+CLINIC&gs_lp=Egxnd3Mtd2l6LXNlcnAiI2RyIG5ldGkncyBTS0lOIEhBSVIgQU5EIE5BSUwgQ0xJTklDMggQABgWGB4YCjIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgMyCBAAGIoFGIYDSONtUP0DWNJqcAF4AZABAJgB_wGgAfskqgEGMC4yNS42uAEDyAEA-AEBwgIHECMYsAMYJ8ICChAAGEcY1gQYsAPCAgQQIxgnwgIHECMYigUYJ8ICBhAAGBYYHsICCxAAGBYYHhjxBBgKwgIFEAAYogTCAgUQIRigAcICCBAhGBYYHhgd4gMEGAAgQYgGAZAGBA&sclient=gws-wiz-serp#rlimm=10764161611293206045' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-google' />
          </a>
          <a href='https://www.instagram.com/dr.netis/' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-instagram' />
          </a>
          <a href='/' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-linkedin' />
          </a>
          <a href='https://www.youtube.com/@Drnetisskinhairandnailclinic' className='me-4 text-reset'>
            <MDBIcon  fab icon='bi bi-youtube' />
          </a>
        </div>
      </section>

      <section className=''style={{backgroundColor:'#1f1f81',color:'snow'}}>
        <MDBContainer className='text-center text-md-start ' >
          <MDBRow className='' style={{Color:'#1f1f81',paddingTop:'30px'}}>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4 text-center' >
              <img className='FooterLogo' src='logoNatis1.png'   alt=''></img>
              <p style={{marginTop:'10px',fontSize:'13px' }}>
              Dr. Sai Prasanth Neti is a distinguished member of the Indian Association of Dermatologists, Venereologists, and Leprologists (IADVL). He holds an M.D. degree , demonstrating his dedication to continuous learning and staying at the forefront of dermatological and cosmetological advancements. 
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
              
                <Link to='/Skin_Whitening_Treatment' className='text-reset'>
                Skin Whitening
                </Link>
              </p>
              <p>
              <Link to='/Pigmentation_Treatment' className='text-reset'>
              Pigmentation Treatment
                </Link>
              </p>
              <p>
              <Link to='/Chemical_Peel_Treatment' className='text-reset'>
              Chemical Peels
                </Link>
              </p>
              <p>
              <Link to='/Hydradermabrasion_Treatment' className='text-reset'>
              Hydradermabrasion
                </Link>
              </p>
              <p>
              <Link to='/Skin_Rejuvenation' className='text-reset'>
              Skin Rejuvenation
                </Link>
              </p>
              <p>
              <Link to='/Stretch_Marks_Treatment' className='text-reset'>
              Stretch Marks
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
              <Link to='/Laser_Hair_Removal' className='text-reset'>
              Laser Hair Removal
                </Link>
              </p>
              <p>
              <Link to='/Carbon_Toning' className='text-reset' style={{fontSize:'16px'}}>
              Carbon Toning 
                </Link>
              </p>
              <p>
              <Link to='/Wrinkles_Treatment' className='text-reset'>
              Wrinkles Treatment
                </Link>
              </p>
              <p>
              <Link to='/Vitiligo_Surgeries' className='text-reset'>
              Vitiligo Surgeries 
                </Link>
              </p>
              <p>
              <Link to='/Laser_Toning' className='text-reset'>
              Laser Toning 
                </Link>
              </p>
              <p>
              <Link to='/Hair_Transplantation' className='text-reset'>
              Hair Transplantation
                </Link>
              </p>
              <p>
              <Link to='/Earlobe_Repair' className='text-reset'>
              Earlobe Repair
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-2'>Contact</h6>
              <p>
                <MDBIcon color='secondary'className='me-2' />
                <i className="bi bi-house-door-fill"></i><span> 1st Floor, C7, Zore Complex, Shop No: 6-3-662, opp. PILLAR No. 1127 Punjagutta, Hyderabad</span>     
              </p>
              <p>
                <MDBIcon color='secondary'  className='me-3' /> 
                <i className="bi bi-telephone-fill">+91 7995233710</i>
              </p>
              <p>
                <MDBIcon color='secondary'className='me-2' />
                <i className="bi bi-house-door-fill"></i><span> Shop No.4, New Science College Ln,Nagarjuna Nagar colony, Ameerpet, Hyderabad</span>     
              </p>
            
              <p>
                <MDBIcon color='secondary'  className='me-3' /> 
                <i className="bi bi-telephone-fill">+91 9704587802</i>
              </p>
              <p>
                <MDBIcon color='secondary'  style={{color:'white'}} className='me-3' />
                <i className="bi bi-envelope-at-fill" style={{fontSize:'small'}}> Example@gmail.com</i>           
              </p>
             
            </MDBCol>
          </MDBRow>
        
         

        </MDBContainer>
        <MDBRow style={{border:'1px solid white'}}>
            <p className='text-center mt-3 mb-3'>Designed By - <span className='DartLink'><a href='http://www.dartmarketing.in/'>Dart Marketing Solutions </a></span></p>
            
          </MDBRow>
      </section>
      
      
      
      
    </MDBFooter>


  
    
    </>
  )
}

export default Footer