import React from 'react'
import { Link } from 'react-router-dom'

const Netis_Skin_And_Hair_Clinic_Products = () => {
  return (
    
    <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase' ,marginTop:'30px' }}>Netis Skin And Hair Clinic Products</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center',marginBottom:'50px' }}>
                        <li ><Link to='/Home' style={{color:'whitesmoke',textDecoration:'none',}}>Home</Link></li>
                    </ul>
                </div>
            </div>
            <div className='container mt-5 text-center' style={{padding:'100px'}}>
                <h1 className=' mt-5'  >Coming Soon....</h1>
            </div> 
            {/* <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#ff7800" }}>Best Centre For Infectious Diseases (CID)</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        Centre For Infectious Diseases in first of its kind state of the microbiology unit established at help hospital Guntur under the eminent guidance of Dr. Kalyan Chakravarthi. This centre has the most modern blood culture system and automated identification of organic carry infection. It is also equipped with CB-NAAT(Cartridge Based Nucleic Acid Amplification Technology) to detect many infections like Tuberculosis using PCR.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Dr. Kalyan Chakravarthy Koganti, a visionary and dedicated medical professional, is the founder of CID Center For Infectious Diseases. With a passion for public health and a mission to combat infectious diseases, Dr. Koganti established this groundbreaking initiative in the city of Guntur.
                        </p>
                       
                       
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='down 2.png' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Our Vision</span>
                        </h4 >
                <p style={{ textAlign: 'justify' }}>
                            At CID Centre For Infectious Diseases, our vision is to create a world where infectious diseases no longer pose a threat to the health and well-being of individuals and communities. We strive to achieve this by employing cutting-edge medical practices, groundbreaking research, and innovative healthcare solutions.
                        </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Our Mission</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Our mission is two-fold. Firstly, we are committed to providing the highest quality healthcare services to patients suffering from infectious diseases. We believe in a patient-centric approach, where every individual receives personalized care, compassion, and access to the most advanced treatments available.
                </p>
                <p style={{ textAlign: 'justify' }}>Secondly, we focus on conducting pioneering research in the field of infectious diseases. Our team of dedicated researchers works tirelessly to unravel the mysteries of various infections, develop effective prevention strategies, and discover breakthrough treatments to combat these diseases effectively.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Our Services</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    <b>At CID Centre For Infectious Diseases, we offer a wide range of comprehensive services:</b>
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Diagnosis and Treatment :</b> Our expert medical team specializes in diagnosing and treating various infectious diseases, ensuring accurate and timely interventions.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Preventive Measures:</b> We emphasize the importance of preventive measures, including vaccinations and education, to protect individuals from infectious diseases.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Research and Development:</b> Our dedicated research team conducts cutting-edge studies to advance our understanding of infectious diseases and improve treatment outcomes.
                </p>

                <p style={{ textAlign: 'justify' }}>
                    <b>Public Health Initiatives:</b> We actively participate in public health initiatives, working closely with government agencies and NGOs to control the spread of infectious diseases in the community.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Why Choose CID Centre For Infectious Diseases?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    <b>Expert Team:</b> Our healthcare professionals are highly skilled and experienced in handling infectious diseases, ensuring the best possible outcomes for our patients.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>State-of-the-Art Facilities:</b> Our centre is equipped with modern medical technology and resources to provide the highest standard of care.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Compassionate Care:</b> We understand the impact of infectious diseases on patients and their families. Our team is dedicated to providing compassionate care throughout the treatment journey.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Innovative Research:</b> By combining clinical expertise with cutting-edge research, we are at the forefront of discovering new ways to combat infectious diseases effectively.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    <b>Community Involvement:</b> We actively engage with the community through awareness programs, health camps, and educational initiatives to promote a healthier society.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Contact Us</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    If you or a loved one is facing the challenges of an infectious disease, don't hesitate to reach out to CID Centre For Infectious Diseases. We are committed to helping you on your journey to recovery and contributing to a world free from the burden of infectious diseases.
                </p>
            </div> */}

        </>
    
  )
}

export default Netis_Skin_And_Hair_Clinic_Products