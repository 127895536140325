import React from 'react'
import { Link } from 'react-router-dom'


const Carbon_Toning = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Carbon Toning Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Carbon Toning</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we bring you the latest in skin rejuvenation technology. Under the expert care of Dr. Sai Prasanth Neti, our clinic offers cutting-edge Carbon Toning treatments designed to revitalize and refresh your skin.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Carbon Toning Treatment?</span>
                        </h4 >

                        <p style={{ textAlign: 'justify' }}><b>Expertise and Experience: </b>Dr. Sai Prasanth Neti is a highly respected dermatologist with extensive experience in advanced skincare techniques. His expertise ensures that you receive the highest standard of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized Treatment Plans: </b>We understand that every individual's skin is unique. Our personalized treatment plans are tailored to address your specific concerns, ensuring optimal results.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Carbon-Toning.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>State-of-the-Art Technology: </b>At Netis Skin and Hair Clinic, we pride ourselves on staying at the forefront of dermatological advancements. Our Carbon Toning treatments utilize cutting-edge technology for safe, effective, and comfortable procedures.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Carbon Toning Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Carbon Toning, also known as Carbon Laser Peel or Hollywood Peel, is a non-invasive procedure that combines a specially formulated carbon lotion with laser energy. The carbon particles are applied to the skin, where they penetrate deep into the pores. When the laser is applied, it targets and vaporizes the carbon particles, effectively exfoliating the skin and stimulating collagen production.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Carbon Toning:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Improved Skin Texture: </b>Refines pores, reduces fine lines, and smoothens skin texture for a youthful appearance.</p>
                <p style={{ textAlign: 'justify' }}><b>Even Skin Tone: </b>Diminishes pigmentation irregularities, resulting in a more balanced complexion.</p>
                <p style={{ textAlign: 'justify' }}><b>Stimulated Collagen Production: </b>Promotes the natural production of collagen, leading to firmer, more resilient skin.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant, Youthful Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, revitalized complexion you deserve.</p>
            </div>

        </>
    )
}

export default Carbon_Toning