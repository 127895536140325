import React from 'react'
import { Link } from 'react-router-dom'


function MNRF_for_Acne_Scar_Treatment_in_Hyderabad() {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>MNRF for Acne Scar Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>MNRF for Acne Scar Treatment</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Acne scars can be a persistent reminder of past skin issues, affecting self-esteem and confidence. However, advancements in dermatological treatments offer hope. One such innovative treatment is Microneedling Radiofrequency (MNRF), which has shown remarkable effectiveness in reducing acne scars.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>What is MNRF?</span>
                        </h4 >
                        <p>Microneedling Radiofrequency (MNRF) is a minimally invasive procedure that combines the benefits of microneedling with the therapeutic effects of radiofrequency energy. The process involves using fine needles to create micro-injuries in the skin while simultaneously delivering controlled radiofrequency energy into the deeper layers. This dual-action stimulates the skin’s natural healing processes, promoting collagen and elastin production.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>How MNRF Works on Acne Scars</span>
                        </h4 >
                        <p>Acne scars result from the damage and inflammation caused by severe acne, leading to irregularities in the skin's texture and appearance. MNRF targets these scars by:</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-1 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='mnrf Acne treatment.jpg' alt=''></img>
                    </div>
                </div>
                <ul>
                    <li>Microneedling: The tiny needles create micro-channels in the skin, triggering a natural healing response. This process promotes the production of new skin cells and collagen, improving skin texture and reducing the appearance of scars.</li>
                    <li>Radiofrequency Energy: The radiofrequency energy penetrates deeper into the skin, heating the tissue and stimulating collagen and elastin production. This enhances skin tightening and scar reduction, providing more substantial and longer-lasting results than microneedling alone.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Benefits of MNRF for Acne Scars</span>
                </h4 >
                <ul>
                    <li>Effective Scar Reduction: MNRF significantly reduces the appearance of various types of acne scars, including atrophic (depressed) scars.</li>
                    <li>Minimal Downtime: The procedure requires little to no downtime, making it convenient for those with busy schedules.</li>
                    <li>Safe for All Skin Types: MNRF is suitable for all skin types and tones, with a low risk of hyperpigmentation or other side effects.</li>
                    <li>Enhanced Skin Texture: Beyond scar reduction, MNRF improves overall skin texture, tone, and firmness, giving a smoother and more youthful appearance.</li>
                </ul>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>What to Expect During and After the Procedure</span>
                </h4 >
                <p>The MNRF procedure typically takes about 30-60 minutes, depending on the treatment area. A topical anesthetic is applied to minimize discomfort. After the treatment, mild redness and swelling may occur, but these side effects usually subside within a few days. Noticeable improvements in skin texture and scar appearance can be seen after a few sessions, with optimal results often achieved after 3-4 treatments spaced several weeks apart.</p>
            </div>

        </>
    )
}

export default MNRF_for_Acne_Scar_Treatment_in_Hyderabad