import React from 'react'
import { Link } from 'react-router-dom'


const Permanent_Eyebrows = () => {
    return (

        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Permanent Eyebrows</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Permanent Eyebrows</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        Welcome to Netis Skin and Hair Clinic, where we bring you the latest in permanent makeup solutions. Under the expert care of Dr. Sai Prasanth Neti, our clinic offers cutting-edge technology for natural-looking, long-lasting, and beautifully defined eyebrows.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Permanent Eyebrows?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Natural-Looking Results: </b>Our permanent eyebrows treatments are meticulously designed to create a subtle, realistic appearance that complements your facial features.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Permanent Eyebrows.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of cosmetic advancements. Our permanent eyebrows treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Permanent Eyebrows Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Permanent eyebrows, also known as microblading or eyebrow tattooing, involve the precise application of pigment to create the appearance of fuller, well-defined eyebrows. This meticulous process results in a natural-looking enhancement that can last for years.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Permanent Eyebrows:</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}><b>Effortless Beauty: </b>Wake up to perfectly shaped and defined eyebrows every day.</p>
                        <p style={{ textAlign: 'justify' }}><b>Time-Saving: </b>Eliminate the need for daily eyebrow makeup application.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized to You: </b>Our treatments are tailored to your unique facial structure and preferences.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Effortless Elegance</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of well-defined eyebrows. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the effortlessly elegant look you desire.</p>
                        <p style={{ textAlign: 'justify' }}>Begin your journey towards a more confident, put-together appearance. Schedule your consultation today, and let us guide you towards the natural, beautifully defined eyebrows you deserve.</p>
            </div>

        </>
    )
}

export default Permanent_Eyebrows