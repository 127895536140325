import React from 'react'
import { Link } from 'react-router-dom'


const summer_skin_care_tips = () => {
  return (
    <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Summer Skin Care Tips</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Summer Skin Care Tips</span>
                        </h1>


                        <p style={{ textAlign: 'justify' }}>
                        With the arrival of summer, it's time to update your skincare routine to keep your skin healthy, protected, and glowing in the heat. From shielding against harmful UV rays to maintaining hydration levels, here's your comprehensive guide to summer skin care tips that will help you look and feel your best all season long.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>1.Protect with Sunscreen:</span>
                        </h4 >
                        <p>One of the most important summer skin care tips is to protect your skin from the sun's harmful UV rays. Opt for a broad-spectrum sunscreen with SPF 30 or higher and apply it generously to all exposed areas of your skin, including your face, neck, ears, and hands. Remember to reapply every two hours, especially if you're swimming or sweating.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>2.Hydrate Inside and Out:</span>
                </h4 >
                <p>The summer heat can leave your skin feeling parched, so it's essential to stay hydrated both internally and externally. Drink plenty of water throughout the day to keep your skin hydrated from within, and use a lightweight, non-comedogenic moisturizer to lock in moisture and prevent dryness.</p>
  
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Skin_care.webp' alt=''></img>
                    </div>
                </div>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>3.Cleanse Gently:</span>
                </h4 >
                <p>Sweat, sunscreen, and excess oil can build up on your skin during the summer, leading to clogged pores and breakouts. Use a gentle cleanser twice a day to remove impurities without stripping away natural oils. Look for a cleanser that is suitable for your skin type and contains ingredients like salicylic acid or tea tree oil to help prevent acne.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>4.Exfoliate Regularly:</span>
                </h4 >
                <p>Regular exfoliation is key to maintaining smooth, radiant skin year-round, but it's especially important during the summer when sweat and sunscreen can cause buildup. Use a gentle exfoliating scrub or chemical exfoliant 2-3 times a week to remove dead skin cells and reveal a fresh, glowing complexion.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>5.Stay Cool:</span>
                </h4 >
                <p>Excessive heat and humidity can exacerbate certain skin conditions like acne, eczema, and rosacea. Stay cool by seeking shade, using fans or air conditioning, and wearing loose, breathable clothing. Avoid hot showers, as they can strip your skin of its natural oils and lead to further irritation.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>6.Protect Your Lips:</span>
                </h4 >
                <p>Don't forget to protect your lips from the sun's rays by using a lip balm with SPF protection. Apply liberally throughout the day, especially before going outdoors, and reapply frequently to keep your lips hydrated and sunburn-free.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>7.Eat a Healthy Diet:</span>
                </h4 >
                <p>Your diet can have a significant impact on the health and appearance of your skin. During the summer months, focus on eating a balanced diet rich in fruits, vegetables, lean proteins, and healthy fats. Foods high in antioxidants, such as berries, tomatoes, and leafy greens, can help protect your skin from sun damage and premature aging.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>8.Opt for Lightweight Formulations:</span>
                </h4 >
                <p>Swap out heavy creams and serums for lightweight, oil-free formulations during the summer months. Look for products labeled "non-comedogenic" to prevent clogged pores and breakouts, and choose gel-based moisturizers and serums that absorb quickly without leaving a greasy residue.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>9.Treat Sunburn Promptly:</span>
                </h4 >
                <p>Despite your best efforts, sunburn can still occur, so it's important to know how to treat it effectively. If you do get sunburned, apply aloe vera gel or a soothing moisturizer to the affected areas to help calm inflammation and promote healing. Avoid further sun exposure until the burn has healed completely.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>10.Practice Sun-Safe Habits:</span>
                </h4 >
                <p>In addition to wearing sunscreen, take other precautions to protect your skin from the sun's harmful rays. Wear protective clothing, such as hats, sunglasses, and lightweight, long-sleeved shirts, and seek shade whenever possible, especially during the peak hours of 10 a.m. to 4 p.m.</p>
              
         
            </div>

        </>
  )
}

export default summer_skin_care_tips