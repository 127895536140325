import React from 'react'
import { Link } from 'react-router-dom'

const haircare_tips_for_summer_season = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Hair Care Tips for the Summer Season</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Hair Care Tips for the Summer Season</span>
                        </h3>

                        <p>With the arrival of summer, it's time to revamp your hair care routine to combat the challenges posed by the sun, humidity, and outdoor activities. Here are seven essential tips to keep your locks healthy and vibrant during the sunny months.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Hydration is Key:</span>
                        </h4>
                        <p>As the temperatures rise, your hair needs extra moisture to combat dryness and frizz. Opt for hydrating shampoos and conditioners that replenish moisture and nourish your strands. Additionally, drink plenty of water to hydrate your hair from the inside out.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Protect from UV Rays:</span>
                        </h4 >
                        <p>Prolonged sun exposure can damage your hair, leading to dryness, color fading, and breakage. Shield your locks from UV rays by wearing hats or using hair products with built-in UV protection. Look for leave-in conditioners or sprays containing SPF to safeguard your strands from sun damage.</p>


                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '250px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='SummerCare.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Minimize Heat Styling:</span>
                </h4 >
                <p>Give your hair a break from heat styling tools during the summer months to prevent further damage. Embrace your natural texture and opt for air-drying or heat-free styling techniques like braids or buns. This will help maintain your hair's moisture balance and reduce the risk of heat-related damage.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Limit Washing Frequency:</span>
                </h4 >
                <p>Over-washing can strip your hair of its natural oils, leaving it dry and prone to breakage. Instead, wash your hair every 2-3 days with a mild shampoo to remove dirt and sweat. On non-wash days, use dry shampoo to refresh your roots and absorb excess oil without over-drying your hair.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Deep Condition Regularly:</span>
                </h4 >
                <p>Treat your hair to a weekly deep conditioning treatment to replenish moisture and repair damage. Choose a deep conditioner enriched with nourishing ingredients like coconut oil or shea butter to hydrate and revive your locks. Leave the conditioner on for at least 10-15 minutes before rinsing thoroughly for best results.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Trim Split Ends:</span>
                </h4 >
                <p>Regular trims are essential for maintaining healthy hair, especially during the summer months. Exposure to the sun and outdoor elements can cause split ends to worsen, leading to further damage. Schedule a trim every 6-8 weeks to remove split ends and keep your hair looking fresh and healthy.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Protect Against Pool Chemicals:</span>
                </h4>
                <p>If you're planning to swim this summer, take steps to protect your hair from chlorine damage. Before swimming, wet your hair with clean water and apply a leave-in conditioner or hair oil to create a barrier against pool chemicals. After swimming, rinse your hair thoroughly with fresh water to remove chlorine residue and follow up with a moisturizing treatment.</p>

            </div>

        </>
    )
}

export default haircare_tips_for_summer_season