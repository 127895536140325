import React from 'react'
import { Link } from 'react-router-dom'


const Antiaging_Treatments = () => {
  return (
     <>
    <div style={{ height: '120px' }}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
      <div className="col text-center strip1" style={{ color: 'white' }}>
        <div className="row mt-5" >
          <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Anti-Aging Treatments</h1>
        </div>
        <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
          <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
        </ul>
      </div>
    </div>

    <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
      <div className="row">
        <div className="col-lg-7 mb-2">
          <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#1f1f81" }}>Anti-Aging Treatment</span>
          </h1>
          <p style={{ textAlign: 'justify' }}>
          Welcome to Netis Skin and Hair Clinic, where we specialize in helping you turn back the clock on aging. Under the expert care of Dr. Sai Prasanth Neti, our clinic offers cutting-edge anti-aging treatments designed to rejuvenate and revitalize your skin.
          </p>
          <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Anti-Aging Treatment?</span>
          </h4 >

          <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Antiaging_Treatments.Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
          <p style={{ textAlign: 'justify' }}><b>Comprehensive Anti-Aging Solutions: </b>We offer a range of personalized anti-aging treatments that target various signs of aging, ensuring a holistic approach to rejuvenation.</p>

        </div>
        <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
          <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Anti-Aging Treatment.jpg' alt=''></img>
        </div>
      </div>
      <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our anti-aging treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

      <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
        <span style={{ color: "#fecb3e" }}>About Anti-Aging Treatments</span>
      </h4 >
      <p style={{ textAlign: 'justify' }}>Our anti-aging treatments are carefully curated to address a variety of concerns, including fine lines, wrinkles, loss of elasticity, and uneven skin tone. These may include:</p>
          <p style={{ textAlign: 'justify' }}><b>Dermal Fillers: </b>Injectable fillers are used to restore volume, smooth out wrinkles, and enhance facial contours.</p>
          <p style={{ textAlign: 'justify' }}><b>Botox® and Dysport®: </b>These neuromodulators temporarily relax muscles responsible for wrinkles, resulting in a smoother, more youthful appearance.</p>
          <p style={{ textAlign: 'justify' }}><b>Laser Resurfacing: </b>Advanced laser technology is used to stimulate collagen production, improving skin texture and reducing the appearance of wrinkles.</p>
      <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
        <span style={{ color: "#fecb3e" }}>Key Benefits of Anti-Aging Treatments:</span>
      </h4 >

      <p style={{ textAlign: 'justify' }}><b>Youthful Appearance: </b>Diminishes the visible signs of aging for a more youthful, refreshed look.</p>
          <p style={{ textAlign: 'justify' }}><b>Improved Skin Texture: </b>Smoothens out fine lines and wrinkles for a more radiant complexion.</p>
          <p style={{ textAlign: 'justify' }}><b>Boosted Confidence: </b>Helps individuals feel more confident about their appearance.</p>


      <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
        <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Timeless Beauty</span>
      </h4 >
      <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, youthful-looking skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the timeless beauty you desire.</p>
          <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, revitalized complexion you deserve.</p>


    </div>

    </>
  )
}

export default Antiaging_Treatments