import React from 'react'
import { Link } from 'react-router-dom'


const Stretch_Marks_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Stretch Marks Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Stretch Marks Treatment</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we specialize in helping you love the skin you're in. Led by the esteemed Dr. Sai Prasanth Neti, our clinic offers cutting-edge treatments for stretch marks. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to diminish the appearance of stretch marks.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Stretch Marks Treatment?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>Expertise You Can Trust: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in cosmetic dermatology. With years of experience and a deep understanding of skin science, he leads our team in providing the highest level of care.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='strechmarksbeforeafter.png' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Personalized Treatment Plans: </b>Recognizing that each individual's skin is unique, we create customized treatment plans to address your specific concerns. Our goal is to restore your skin's natural beauty and boost your confidence.</p>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our stretch marks treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Our Stretch Marks Treatment Options:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Microneedling with PRP: </b>This innovative treatment combines microneedling with platelet-rich plasma (PRP) to stimulate collagen production, reducing the appearance of stretch marks and promoting skin rejuvenation.</p>
                <p style={{ textAlign: 'justify' }}><b>Laser Therapy: </b>Advanced laser treatments target the affected areas, promoting collagen production and improving the texture and color of stretch marks.</p>
                <p style={{ textAlign: 'justify' }}><b>Topical Solutions: </b>We offer specialized creams and serums designed to nourish and rejuvenate the skin, contributing to the reduction of stretch marks.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Benefits of Stretch Marks Treatment:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Improved Texture: </b>Enhance skin's texture and tone, leaving it smoother and more youthful.</p>
                <p style={{ textAlign: 'justify' }}><b>Reduction of Fine Lines and Wrinkles: </b>Stimulate collagen production for a plumper, more rejuvenated appearance.</p>
                <p style={{ textAlign: 'justify' }}><b>Youthful Radiance: </b>Uncover a radiant complexion that exudes vitality and health.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Beautiful, Smooth Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that stretch marks can have on your confidence and well-being. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the skin you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards smoother, more beautiful skin. Schedule your consultation today, and let us guide you towards the radiant, stretch mark-free complexion you deserve.</p>
            </div>

        </>
    )
}

export default Stretch_Marks_Treatment