import React from 'react'
import { Link } from 'react-router-dom'


const Scalp_Micropigmentation = () => {
    return (
        <>

            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Scalp Micropigmentation</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Scalp Micropigmentation</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we offer specialized Scalp Micropigmentation treatments under the expert care of Dr. Sai Prasanth Neti. Our clinic combines advanced technology with precise techniques to provide comprehensive solutions for hair loss concerns.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Scalp Micropigmentation?</span>
                        </h4 >

                        <p style={{ textAlign: 'justify' }}><b>Specialized Expertise: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in advanced hair care, including specialized scalp micropigmentation procedures. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized Solutions: </b>We understand that each individual's hair loss journey is unique. Our scalp micropigmentation treatments are tailored to address specific concerns, ensuring optimal results.</p>





                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Scalp Micropigmentation.webp' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of hair restoration advancements. Our scalp micropigmentation procedures utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Scalp Micropigmentation Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Scalp Micropigmentation (SMP) is a non-invasive procedure that involves the application of micro-sized pigment deposits to the scalp, creating the appearance of a fuller head of hair. This innovative technique can effectively camouflage thinning hair, scars, or create the look of a closely-shaved head.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Scalp Micropigmentation:</span>
                </h4 >

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>For the Face:</span>
                </h5 >
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>For the Hair:</span>
                </h5 >
                <p style={{ textAlign: 'justify' }}><b>Natural-Looking Results: </b>Achieve the appearance of a fuller head of hair or a closely-shaved head with a natural and realistic finish.</p>
                <p style={{ textAlign: 'justify' }}><b>Non-Invasive: </b>Unlike surgical procedures, SMP is a non-invasive solution with little to no downtime.</p>
                <p style={{ textAlign: 'justify' }}><b>Boosted Confidence: </b>Enjoy renewed confidence and a more youthful appearance.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Natural-Looking Hair</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that hair loss can have on your confidence and self-esteem. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the natural, fuller look you desire.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards hair restoration and renewed confidence. Schedule your consultation today, and let us guide you towards the revitalized, natural appearance you deserve.</p>


            </div>

        </>
    )
}

export default Scalp_Micropigmentation