import React from 'react'
import { Link } from 'react-router-dom'


const Pigmentation_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Pigmentation Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Pigmentation Treatment</span>
                        </h1>


                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we specialize in restoring your skin's natural glow. Led by the esteemed Dr. Sai Prasanth Neti, our clinic offers cutting-edge treatments for pigmentation concerns. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to address uneven skin tone and hyperpigmentation.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Pigmentation Treatment?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>
                            Expertise That Matters: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist renowned for his proficiency in treating acne and acne scars. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='pigmentation.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Tailored Treatment Plans: </b>We recognize that each individual's skin is unique. Our personalized treatment plans are meticulously crafted to address your specific concerns, ensuring a path to clearer, healthier skin.
                </p>

                <p style={{ textAlign: 'justify' }}><b>
                    Cutting-Edge Technology: </b>Leveraging the latest advancements in dermatological technology, we employ techniques that are at the forefront of the industry. Our treatments are not only effective but also designed for minimal discomfort and downtime.
                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Our Acne & Acne Scars Treatments</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Advanced Laser Therapy: </b>Experience the transformative power of laser technology in combating acne. Our advanced laser treatments target the root causes of acne, promoting clearer skin and preventing future breakouts.</p>
                <p style={{ textAlign: 'justify' }}><b>Microneedling with PRP: </b>This revolutionary treatment combines microneedling with platelet-rich plasma (PRP) to stimulate collagen production, reducing the appearance of acne scars and promoting overall skin rejuvenation.</p>
                <p style={{ textAlign: 'justify' }}><b>Chemical Peels: </b>Our range of specialized chemical peels effectively exfoliates the skin, reducing acne, diminishing scars, and leaving you with a refreshed, radiant complexion.</p>
                <p style={{ textAlign: 'justify' }}><b>Topical Solutions:</b> We offer a range of potent creams and serums specifically formulated to treat acne and minimize the appearance of scars. These targeted solutions work to restore your skin's natural balance.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Begin Your Journey to Clearer, Healthier Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    At Netis Skin and Hair Clinic, we understand the impact that acne and acne scars can have on your confidence and well-being. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are committed to helping you regain control of your skin.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    Embark on a transformative journey towards a brighter, blemish-free future. Schedule your consultation today, and let us guide you towards the radiant complexion you deserve.
                </p>
            </div>

        </>
    )
}

export default Pigmentation_Treatment