import React from 'react'
import { Link } from 'react-router-dom'


const Dr_Sai_Prasanth_Neti = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Dr Sai Prasanth Neti</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row mt-2'>
                    <div className="col-lg-7 mb-2 mt-5">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important',  color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Dr Sai Prasanth Neti</span>
                        </h1>
                        <p style={{textAlign:'justify'}}>
                        Dr. Sai Prasanth Neti is a distinguished member of the Indian Association of Dermatologists, Venereologists, and Leprologists (IADVL). He holds an M.D. degree , demonstrating his dedication to continuous learning and staying at the forefront of dermatological and cosmetological advancements.
                        </p>
                        <p style={{textAlign:'justify'}}>Dr. Sai Prasanth Neti is a highly regarded dermatologist, boasting an impressive academic background. and is also an esteemed member of the Federation of Aesthetic Dermatology and Laser Surgeons (FADS). Dr. Neti's unwavering passion for dermatology, cosmetology, and laser surgery underscores his commitment to delivering exceptional care.
Our Services</p>
                        <p style={{textAlign:'justify'}}>At DR.NETI'S Skin Hair and Nail Clinic, we offer a wide range of services in the fields of Cosmetology and Trichology, designed to address your unique skin, hair, and nail concerns. Our services include:</p>
                     
                    </div>
                    <div className="col-lg-5 servicesimg mt-5" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '280px', boxShadow: 'grey' }} id="ServicesImG" src='DrNetisPic.png' alt=''></img>
                    </div>
                    
                </div>
                <p style={{textAlign:'justify'}}><b>Cosmetic Dermatology: </b> Experience a rejuvenated and youthful appearance with our state-of-the-art cosmetic dermatology treatments, including Botox, dermal fillers, and chemical peels.</p>
                        <p style={{textAlign:'justify'}}><b>Hair Restoration: </b> Restore your confidence with our advanced hair restoration techniques, such as hair transplants, PRP therapy, and hair growth medications.</p>
                        <p style={{textAlign:'justify'}}><b>Skin Treatments: </b>Achieve clear, healthy skin with our customized skin care plans, addressing conditions like acne, pigmentation, and rosacea.</p>
                        <p style={{textAlign:'justify'}}><b>Nail Care: </b>Experience top-notch nail care with our professional services. We specialize in treating nail infections, solving nail problems, and providing expert care for nail injuries. Our experienced team, advanced technology, and patient-centric approach ensure your nails receive the best care possible.</p>
                        <p style={{textAlign:'justify'}}><b>Trichology: </b>Our trichology services focus on the diagnosis and treatment of hair and scalp conditions, ensuring your hair is at its best.</p>
                       
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}> Why Choose Us?</span>
                </h4 >
                        <p style={{textAlign:'justify'}}><b>Expertise: </b>Dr. Neti's extensive training and experience in dermatology and cosmetology ensure that you receive the highest level of care.</p>
                        <p style={{textAlign:'justify'}}><b>Personalized Approach: </b>We understand that each patient is unique. We tailor our treatments to meet your individual needs and goals.</p>
                        <p style={{textAlign:'justify'}}><b>Cutting-Edge Technology: </b>Our clinic is equipped with the latest technologies and treatments to provide you with the best possible results.</p>
                        <p style={{textAlign:'justify'}}><b>Patient-Centric Care: </b>Your comfort and satisfaction are our top priorities. We strive to make every visit a positive and comfortable experience.</p>
            </div>
        </>
    )
}

export default Dr_Sai_Prasanth_Neti