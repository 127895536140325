import React from 'react'
import { Link } from 'react-router-dom'


const Skin_Rejuvenation = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Skin Rejuvenation</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Skin Rejuvenation</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we specialize in unlocking the true potential of your skin. Led by the esteemed Dr. Sai Prasanth Neti, our clinic offers state-of-the-art skin rejuvenation treatments. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to revitalize and restore their complexion.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Skin Rejuvenation?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b></b>Pioneers in Dermatology: Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='laser-skin-resurfacing.jpeg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b></b>Tailored Treatment Plans: Recognizing that each individual's skin is unique, we create personalized treatment plans to address your specific concerns. Our goal is to reveal a more youthful, radiant complexion that is tailored to your skin's unique needs.</p>
                <p style={{ textAlign: 'justify' }}><b></b>Cutting-Edge Technology: At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our skin rejuvenation treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Our Skin Rejuvenation Treatments:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b></b>Advanced Laser Therapy: Harness the power of laser technology to stimulate collagen production, reduce fine lines, and improve overall skin texture and tone.</p>
                <p style={{ textAlign: 'justify' }}><b></b>Microneedling with PRP: This revolutionary treatment combines microneedling with platelet-rich plasma (PRP) to enhance collagen production, leading to firmer, more youthful skin.</p>
                <p style={{ textAlign: 'justify' }}><b></b>Hydradermabrasion: This multi-step treatment exfoliates, cleanses, and infuses nourishing serums, resulting in a smoother, more radiant complexion</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Benefits of Skin Rejuvenation:</span>
                </h4 >

                
                <p style={{ textAlign: 'justify' }}><b></b>Improved Texture: Enhance skin's texture and tone, leaving it smoother and more youthful.</p>
                        <p style={{ textAlign: 'justify' }}><b></b>Reduction of Fine Lines and Wrinkles: Stimulate collagen production for a plumper, more rejuvenated appearance.</p>
                        <p style={{ textAlign: 'justify' }}><b></b>Youthful Radiance: Uncover a radiant complexion that exudes vitality and health.</p>

                       


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant, Youthful Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b></b>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}><b></b>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, rejuvenated complexion you deserve.</p>
            </div>

        </>
    )
}

export default Skin_Rejuvenation