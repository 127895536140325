import React from 'react'
import { Link } from 'react-router-dom';

const Chemical_Peel_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Chemical Peel Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Chemical Peel Treatment</span>
                        </h1>


                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we specialize in rejuvenating your skin's natural beauty. Under the expert guidance of Dr. Sai Prasanth Neti, our clinic offers state-of-the-art chemical peel treatments. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to refresh and revitalize their complexion.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Chemical Peel Treatment?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>A Leader in Dermatology: </b> Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in cosmetic dermatology. With years of experience and a deep understanding of skin science, he leads our team in providing the highest level of care.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Chemical peel.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Customized Treatment Plans: </b>Recognizing that every skin type is unique, we create personalized treatment plans to address your specific concerns. Our goal is to reveal a more youthful, radiant complexion tailored to your skin's needs.</p>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our chemical peel treatments utilize the latest technology, ensuring both efficacy and comfort for our patients.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Chemical Peels</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Chemical peels are non-invasive treatments that involve the application of a chemical solution to the skin, which exfoliates the top layer, revealing smoother, more even-toned skin beneath. They are effective in treating a range of skin concerns, including:</p>
                <p style={{ textAlign: 'justify' }}><b>Uneven Pigmentation: </b>Diminish dark spots, sun damage, and hyperpigmentation for a more balanced complexion.</p>
                <p style={{ textAlign: 'justify' }}><b>Acne and Acne Scars: </b>Improve acne-prone skin and reduce the appearance of acne scars, promoting clearer, smoother skin.</p>
                <p style={{ textAlign: 'justify' }}><b>Fine Lines and Wrinkles: </b>Stimulate collagen production, reducing the signs of aging and revealing a more youthful appearance.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the importance of having healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant complexion you deserve.</p>


            </div>

        </>
    )
}

export default Chemical_Peel_Treatment