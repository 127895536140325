import React from 'react'
import { Link } from 'react-router-dom'


const PRP_Treatment_In_Hyderabad = () => {
    return(
        <>
        <div style={{ height: '120px' }}></div>
        <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
            <div className="col text-center strip1" style={{ color: 'white' }}>
                <div className="row mt-5" >
                    <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>PRP Treatment</h1>
                </div>
                <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                    <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                </ul>
            </div>
        </div>

        <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
            <div className="row">
                <div className="col-lg-7 mb-2">
                    <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "#1f1f81" }}>PRP Treatment</span>
                    </h1>
                    <p style={{ textAlign: 'justify' }}>
                    In this article, we will explain what PRP therapy is, how it works, the benefits you could experience over time, and the results of past cases.
                    </p>
                    <p style={{textAlign:'justify'}}>
                    You may be feeling apprehensive about pursuing PRP therapy for hair loss. The after-effects have not been discussed as thoroughly as they probably should be on mainstream sites because the procedure is relatively new.
                    </p>
                    <p style={{textAlign:'justify'}}>
                    Understandably, you feel a little nervous, but PRP therapy to promote hair regrowth has produced positive results in the past, and you should consider giving it a try!
                    </p>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "#fecb3e" }}>The PRP Procedure</span>
                    </h4 >
                    <p style={{textAlign:'justify'}}>PRP stands for “platelet-rich plasma.” The procedure consists of drawing your own blood, usually from your arm, spinning it down into three layers in a centrifuge, and injecting only the best plasma rich in platelets into your scalp through microneedling. If you can get routine blood tests done by your doctor and you are immunized regularly, PRP treatment won’t be too different from what you are already used to.</p>
               </div>
                <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                    <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='PRP-for-face.webp' alt=''></img>
                </div>
            </div>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#fecb3e" }}>How PRP Therapy Works</span>
            </h4 >
            <p style={{textAlign:'justify'}}>The platelet-rich plasma that is injected into your scalp works to heal affected areas and stimulate reparative cells through the use of growth factors. Growth factors promote the formation of substances like collagen, which is also used in anti-aging serums. This, in turn, will likely show signs of the regrow of your hair. It’s an all-natural formula, so you don’t have to worry about harsh chemicals.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#fecb3e" }}>The Benefits of PRP Therapy Over Time</span>
            </h4 >
           <p style={{textAlign:'justify'}}>The PRP procedure comes in more than one dose. It is a series of injections carried out over a period of weeks and redone over several months, so you will begin to see changes as you progress through the treatment. What you may notice are as follows:</p>
           <ul style={{textAlign:'justify'}}>
            <li>Your hair may become thicker and denser in the problem areas being treating</li>
            <li>The appearance of balding and thinning spots may be reversed</li>
            <li>Your receding hairline may begin to grow back in</li>
            <li>Your self-confidence will increase gradually as those problem areas may seem to disappear</li>
           </ul>
           <p style={{textAlign:'justify'}}>Though this may all sound too good to be true, you had better start believing it! The results have indicated that PRP therapy could, in fact, achieve everything it states. Let’s go over some of those cases to ease your worries.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#fecb3e" }}>Who Does PRP Therapy Benefit the Most?</span>
            </h4 >
            <p style={{textAlign:'justify'}}>Although PRP for hair procedures can help a wide array of patients with different hair loss-related conditions, there are a few groups that have been more widely studied and have noticed the best and most effective results. These groups are the following:</p>
            <ul style={{textAlign:'justify'}}>
                <li>Those suffering from androgenic alopecia. This condition is also known as male/female pattern baldness. Women can also experience hair loss and can be treated alongside men.</li>
                <li>Those suffering from more recent hair loss. This is because the longer your condition persists, the less likely it is that treatment will work, though it may still produce some beneficial results.</li>
            </ul>
            
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                <span style={{ color: "#fecb3e" }}>How to Achieve Positive Results from PRP Hair Restoration</span>
            </h4 >
            <p style={{textAlign:'justify'}}>Although it is never a guarantee that you will qualify for this procedure, you should talk it over with a certified dermatologist to determine whether PRP therapy is the right choice for you. If it is decided that you are a prime candidate for the procedure, then you should expect to go through this regimen:</p>
            <ul style={{textAlign:'justify'}}>
                <li>Three PRP injections over a period of four to six weeks</li>
                <li>Regular maintenance injections every four to six months</li>
            </ul>
            <p style={{textAlign:'justify'}}>If you follow these appointments and stick with the procedure, you may notice some positive results that will leave you feeling confident and happy again.</p>
                </div>

    </>
    )
  
}

export default PRP_Treatment_In_Hyderabad