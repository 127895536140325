import React from 'react'
import { Link } from 'react-router-dom'


const Laser_treatment_options_your_skin_and_hair = () => {
  return (
   <>
    <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Laser Treatment for Your Skin and Hair!</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Laser Treatment for Your Skin and Hair!</span>
                        </h1>
                       <p>Do you know lasers might be the best thing that can happen to your skin? There is literally a laser procedure available for hair reduction, acne scars, pigmentation, tattoo marks, sunspots, fine lines, brown spots or any other complexion issue that you might have.</p>
                       <p>We agree that there might be several topical creams available in the market, but nothing can rejuvenate your skin like a laser treatment. Since the technology is constantly changing, it is easy to be overwhelmed when you start researching about the options.</p>
                       <p>Dr Sai Prasanth Neti, Dermatologist at Hyderabad Park says, that there are powerful and efficacious equipment available with us for treating all types of skin issues. The two most known machines used are:</p>
                       <ul>
                        <li>Revlite Laser</li>
                        <li>Soprano Ice</li>
                       </ul>                  
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='LaserTreatment.jpg' alt=''></img>
                    </div>
                </div>

              
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>How does Revlite Laser System Works?</span>
                        </h4 >
                       <p>A state of art laser machine is used for treating several skin problems, including hypigmentary disorders like irregular pigmentation, tattoo, open pores, fine lines, melisma and facial rejuvenation.</p>
                       <p>This Q swtich and Yag laser is a non-invasive treatment and works by stimulating the production of collagen under the skin because the new collagen reduces the fine lines and wrinkles. This treatment also splits the melanocytic cells (pigment forming cells) and tattoo ink onto smaller fragments so that they get readily absorbed by the skin.</p>
                       <p>You may require 4-5 sittings depending on the severity of the problem. It is a painless treatment where topical numbing cream is applied (it is safe to use the cream on any part of the body). The person can resume the duties on the same day.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>What is the Specialty of Soprano Ice?</span>
                        </h4 >
                        <p>It is an advanced laser hair removal technology that incorporates multiple laser wavelengths and technologies to treat hair problems of patients. This painless platform is equipped with a special SHR mode, which painlessly treats a larger part of the body. The Sweeping in Motion™ technique of moving the applicator repeatedly over the treatment area ensures full coverage, while the Ice™ technique cools the skin surface, thereby preventing superficial burns. You may require 6-8 sittings depending upon the thickness of the hair follicles. It is advisable that you devise a detailed hormonal treatment for better results. </p>


              
            </div>
   </>
  )
}

export default Laser_treatment_options_your_skin_and_hair