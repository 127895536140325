import React from 'react'
import { Link } from 'react-router-dom'

const How_To_Take_Skin_Care_In_Winter = () => {
    return (

        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Winter Skin Care</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Winter Skin Care: Nurturing Your Skin Through the Chill</span>
                        </h1>
                        <ul>
                            <li>Welcome readers and set the stage for the importance of winter skincare.
                            </li>
                            <li>Briefly discuss the challenges that winter poses for the skin.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Understanding Winter Skin:</span>
                        </h4 >
                        <ul>
                            <li>Explain how colder temperatures and lower humidity levels affect the skin.</li>
                            <li>Discuss common winter skin issues like dryness, flakiness, and sensitivity.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Hydration is Key:</span>
                        </h4 >
                        <ul>
                            <li>Emphasize the importance of using a rich, hydrating moisturizer to combat dryness.</li>
                            <li>Discuss the benefits of using ingredients like hyaluronic acid and ceramides.</li>
                        </ul>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='winterSkinCare.webp' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Gentle Cleansing:</span>
                </h4>
                <ul>
                    <li>Recommend using a mild, creamy cleanser to cleanse without stripping natural oils.</li>
                    <li>Advise against using hot water, which can further dry out the skin.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Exfoliate Wisely:</span>
                </h4 >
                <ul>
                    <li>Explain the benefits of exfoliation in removing dead skin cells and promoting a brighter complexion.</li>
                    <li>Encourage the use of a gentle exfoliant, such as a chemical exfoliator, once or twice a week.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Protect from UV Rays:</span>
                </h4 >
                <ul>
                    <li>Remind readers that UV rays are still present in winter and can cause skin damage.</li>
                    <li>Advocate for the use of a broad-spectrum sunscreen with at least SPF 30.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Combat Dry Lips and Hands:</span>
                </h4 >
                <ul>
                    <li>Provide tips for keeping lips hydrated with a nourishing lip balm.</li>
                    <li>Recommend using a thick hand cream or ointment to prevent dry, cracked hands.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Nourishing Masks and Treatments:</span>
                </h4 >
                <ul>
                    <li>Suggest using hydrating masks or treatments to replenish moisture levels.
                    </li>
                    <li>Offer DIY mask recipes or recommend specific products.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Layering Products:</span>
                </h4 >
                <ul>
                    <li>Guide readers on the order of applying skincare products for maximum effectiveness (e.g., serums, moisturizers, sunscreen).
                    </li>
                    <li>Explain how layering helps seal in moisture.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Nutrition for Glowing Skin:</span>
                </h4 >
                <ul>
                    <li>Encourage a balanced diet rich in fruits, vegetables, and Omega-3 fatty acids.
                    </li>
                    <li>Highlight foods that promote skin health.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Stay Hydrated Indoors:</span>
                </h4 >
                <ul>
                    <li>Advise using a humidifier to add moisture to indoor air and combat dryness caused by heating systems.</li>
                   
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Mindful Skincare for Special Concerns:</span>
                </h4 >
                <ul>
                    <li>Provide tailored tips for individuals with specific skin types (e.g., oily, sensitive) or conditions (e.g., eczema, rosacea).</li>
                </ul>
              
            </div>
        </>

    )
}

export default How_To_Take_Skin_Care_In_Winter