import React from 'react'
import { Link } from 'react-router-dom'


const Growth_factor_concentrate_therapy = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Growth factor concentrate therapy (GFC)</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Growth Factor Concentrate Therapy (GFC)</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Welcome to Netis Skin and Hair Clinic, where we introduce you to the revolutionary Growth Factor Concentrate (GFC) Therapy. Under the expert guidance of Dr. Sai Prasanth Neti, our clinic offers this cutting-edge treatment designed to stimulate natural skin rejuvenation.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for GFC Therapy?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}><b>Pioneering Dermatology: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>

          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Growth factor therapy.jpg' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}><b>Innovative Treatment: </b>GFC Therapy is a breakthrough in skin rejuvenation. It utilizes your body's natural growth factors to promote cell regeneration and tissue repair, resulting in healthier, more youthful skin.</p>
        <p style={{ textAlign: 'justify' }}><b>Tailored to You: </b>We understand that each individual's skin is unique. Our GFC Therapy is personalized to address your specific concerns, ensuring optimal results.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>About Growth Factor Concentrate (GFC) Therapy</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>GFC Therapy involves the extraction and concentration of growth factors from your own blood. These growth factors play a crucial role in cell renewal and tissue repair. When applied to the skin, they stimulate collagen production, improve skin texture, and enhance overall complexion.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Key Benefits of GFC Therapy:</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}><b>Natural Rejuvenation: </b>Harnesses your body's own growth factors to stimulate natural skin regeneration.</p>
        <p style={{ textAlign: 'justify' }}><b>Improved Texture: </b>Enhances skin's texture, making it smoother and more youthful.</p>
        <p style={{ textAlign: 'justify' }}><b>Collagen Stimulation: </b>Boosts collagen production, leading to firmer, more resilient skin.</p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Naturally Beautiful Skin</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we believe in the power of natural solutions for healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
        <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, rejuvenated complexion you deserve.</p>


      </div>

    </>
  )
}

export default Growth_factor_concentrate_therapy