import React from 'react'
import { Link } from 'react-router-dom'


const Nail_Surgery = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>nail surgery</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Nail Surgery</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
            Welcome to Netis Skin and Hair Clinic, where we offer advanced nail surgery treatments under the expert care of Dr. Sai Prasanth Neti. Our clinic combines specialized expertise with cutting-edge technology to provide comprehensive solutions for nail-related concerns.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Nail Surgery?</span>
            </h4 >


            <p style={{ textAlign: 'justify' }}><b>Specialized Dermatological Expertise: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in advanced skin care, which includes specialized nail treatments. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
            <p style={{ textAlign: 'justify' }}><b>Personalized Treatment Plans: </b>We understand that each individual's nail condition is unique. Our personalized nail surgery treatments are tailored to address specific concerns, ensuring optimal results.</p>
         </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='NAIL SURGERY1.jpg' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our nail surgery procedures utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>About Nail Surgery Treatment</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>Nail surgery encompasses a range of procedures designed to address various nail conditions, such as ingrown nails, fungal infections, and other nail abnormalities. These procedures may include partial or complete nail removal, excision of nail bed tissue, and other specialized techniques.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Key Benefits of Nail Surgery:</span>
        </h4 >

        <p style={{ textAlign: 'justify' }}><b>Effective Resolution: </b>Provides a lasting solution for persistent nail issues that may not respond to conventional treatments.</p>
            <p style={{ textAlign: 'justify' }}><b>Minimal Discomfort: </b>Procedures are performed under local anesthesia to ensure comfort during the process.</p>
            <p style={{ textAlign: 'justify' }}><b>Promotes Nail Health: </b>Helps restore the health and appearance of the nail, promoting overall well-being.</p>
  


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Healthy, Beautiful Nails</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the importance of healthy, aesthetically pleasing nails. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the healthy, beautiful nails you desire.</p>
            <p style={{ textAlign: 'justify' }}>Begin your journey towards nails that look and feel their best. Schedule your consultation today, and let us guide you towards the confident, comfortable nail health you deserve.</p>
           

      </div>

    </>
  )
}

export default Nail_Surgery