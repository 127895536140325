import React from 'react'
import { Link } from 'react-router-dom'


const Hair_Transplantation = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>hair transplantation</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Hair Transplantation</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we specialize in state-of-the-art hair transplantation treatments. Under the expert care of Dr. Sai Prasanth Neti, our clinic combines cutting-edge technology with a personalized approach to help you achieve natural, fuller hair.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Hair Transplantation?</span>
                        </h4 >

                        <p style={{ textAlign: 'justify' }}><b>Distinguished Expertise: </b>Dr. Sai Prasanth Neti is a renowned dermatologist known for his proficiency in advanced hair care, including specialized hair transplantation procedures. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Hair Transplantation.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Customized Treatment Plans: </b>We understand that each individual's hair loss pattern is unique. Our hair transplantation treatments are tailored to address specific concerns, ensuring optimal results.</p>
                <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of hair restoration advancements. Our hair transplantation procedures utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Hair Transplantation Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Hair transplantation is a surgical procedure that involves the extraction of hair follicles from a donor area, typically the back or sides of the scalp, and transplanting them to areas with thinning or no hair. The transplanted follicles grow naturally, resulting in fuller, natural-looking hair.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Hair Transplantation:</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}><b>Natural-Looking Results: </b>Achieve a fuller head of hair that looks and feels completely natural.</p>
                <p style={{ textAlign: 'justify' }}><b>Permanent Solution: </b>Hair transplants provide a long-lasting solution for hair loss.</p>
                <p style={{ textAlign: 'justify' }}><b>Boosted Confidence: </b>Enjoy renewed confidence and a more youthful appearance.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Fuller, Natural Hair</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that hair loss can have on your confidence and self-esteem. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the natural, fuller hair you desire.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards hair restoration and renewed confidence. Schedule your consultation today, and let us guide you towards the lush, natural locks you deserve.</p>
            </div>

        </>
    )
}

export default Hair_Transplantation