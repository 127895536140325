import React from 'react'
import { Link } from 'react-router-dom'


const Hydradermabrasion_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Hydradermabrasion Treatment</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Hydradermabrasion Treatment</span>
                        </h1>
                       <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, your gateway to rejuvenated, radiant skin. Led by the esteemed Dr. Sai Prasanth Neti, our clinic introduces the transformative power of Hydradermabrasion treatments. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking a refreshed and revitalized complexion.                </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Hydradermabrasion Treatment?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='hydradermabrasion.jpeg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Customized Approach: </b>Recognizing that each individual's skin is unique, we create personalized treatment plans to address your specific concerns. Our goal is to reveal a more youthful, radiant complexion tailored to your skin's needs.</p>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our Hydradermabrasion treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Hydradermabrasion</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Hydradermabrasion is a non-invasive, multi-step treatment that combines the benefits of exfoliation, cleansing, hydration, and infusion of nourishing serums. The treatment gently exfoliates dead skin cells while simultaneously delivering hydrating and skin-rejuvenating solutions. This results in a smoother, more radiant complexion with improved texture and tone.</p>
                <p style={{ textAlign: 'justify' }}><b>Deep Exfoliation: </b>Gently removes dead skin cells, unclogging pores and revealing fresh, glowing skin.</p>
                <p style={{ textAlign: 'justify' }}><b>Hydration Infusion: </b>Delivers essential nutrients and hydration deep into the skin for a plump, supple complexion.</p>
                <p style={{ textAlign: 'justify' }}><b>Reduces Fine Lines and Wrinkles: </b>Stimulates collagen production, reducing the signs of aging and promoting a more youthful appearance.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Hydradermabrasion:</span>
                </h4 >

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}> Embark on Your Journey to Radiant, Hydrated Skin</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, hydrated complexion you deserve.</p>

            </div>

        </>
    )
}

export default Hydradermabrasion_Treatment