import React from 'react'
import { Link } from 'react-router-dom'


const Earlobe_Repair = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Earlobe Repair</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Earlobe Repair </span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        Welcome to Netis Skin and Hair Clinic, where we offer specialized Earlobe Repair treatments under the expert care of Dr. Sai Prasanth Neti. Our clinic combines advanced technology with precise techniques to provide comprehensive solutions for earlobe-related concerns.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Earlobe Repair?</span>
                        </h4 >

                        <p style={{ textAlign: 'justify' }}><b>Specialized Expertise: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in advanced skin care, including specialized earlobe repair procedures. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized Solutions: </b>We understand that each individual's earlobe condition is unique. Our earlobe repair treatments are tailored to address specific concerns, ensuring optimal results.</p>                      
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='EARLOBE REPAIR.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our earlobe repair procedures utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Earlobe Repair Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Earlobe repair is a specialized procedure aimed at correcting various concerns related to the earlobes, such as torn or stretched piercings, or earlobe deformities. The procedure involves precise techniques to reshape and restore the natural contour and symmetry of the earlobe.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Earlobe Repair:</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}><b>Restores Aesthetic Appeal: </b>Improves the appearance of the earlobe, enhancing overall facial symmetry.</p>
                        <p style={{ textAlign: 'justify' }}><b>Enhances Piercing Results: </b>Repairs stretched or damaged piercings, allowing for the possibility of re-piercing if desired.</p>
                        <p style={{ textAlign: 'justify' }}><b>Boosts Confidence: </b>Helps individuals feel more confident about the appearance of their ears.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Beautiful, Restored Earlobes</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that earlobe concerns can have on your overall appearance and confidence. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the beautiful, restored earlobes you desire.</p>
                        <p style={{ textAlign: 'justify' }}>Begin your journey towards earlobes that look and feel their best. Schedule your consultation today, and let us guide you towards the confident, comfortable earlobe appearance you deserve.</p>


            </div>

        </>
    )
}

export default Earlobe_Repair