import React from 'react'
import { Link } from 'react-router-dom'


const Prp_For_Face_And_Hair = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>PRP For Face And Hair</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>PRP For Face And Hair</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we offer state-of-the-art PRP (Platelet-Rich Plasma) treatments for both your face and hair. Under the expert guidance of Dr. Sai Prasanth Neti, our clinic utilizes advanced technology to harness the natural power of your body for revitalized skin and luscious locks.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for PRP Treatment?</span>
                        </h4 >
                         <p style={{ textAlign: 'justify' }}><b>Distinguished Dermatological Expertise: </b>Dr. Sai Prasanth Neti is a renowned dermatologist known for his expertise in advanced skin and hair care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                         <p style={{ textAlign: 'justify' }}><b>Comprehensive PRP Solutions: </b>We specialize in PRP treatments for both the face and hair, offering a holistic approach to rejuvenation. Our personalized treatment plans are tailored to address your specific concerns, ensuring optimal results.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='PRP-for-Face-and-Hair.jpg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge PRP Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our PRP treatments utilize advanced technology to ensure maximum effectiveness and comfort for our patients.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About PRP For Face And Hair Treatment</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>PRP therapy involves the extraction and concentration of growth factors from your own blood. These growth factors play a crucial role in cell renewal and tissue repair. When applied to the skin or scalp, they stimulate collagen production, improve skin texture, and promote hair growth.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of PRP Treatment:</span>
                </h4 >

                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>For the Face:</span>
                </h5 >
                <p style={{ textAlign: 'justify' }}><b>Natural Rejuvenation: </b>Utilizes your body's own growth factors to stimulate natural skin regeneration.</p>
                <p style={{ textAlign: 'justify' }}><b>Improved Texture: </b>Enhances skin's texture, making it smoother and more youthful.</p>
                <p style={{ textAlign: 'justify' }}><b>Collagen Stimulation: </b>Boosts collagen production, leading to firmer, more resilient skin.</p>
                <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>For the Hair:</span>
                </h5 >
                <p style={{ textAlign: 'justify' }}><b>Promotes Hair Growth: </b>Stimulates dormant hair follicles, promoting new hair growth.</p>
                <p style={{ textAlign: 'justify' }}><b>Thicker, Healthier Hair: </b>Enhances the quality and thickness of existing hair.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant Skin and Luscious Hair</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we believe in the power of natural solutions for healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, rejuvenated complexion you deserve.</p>


            </div>

        </>
    )
}

export default Prp_For_Face_And_Hair