import React from 'react'
import { Link } from 'react-router-dom'


const Hair_care_Monsoon = () => {
  return (
   <>
    <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Hair Care in Monsoon</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Hair Care in Monsoon</span>
                        </h1>
                        <p>Monsoons are the time of the year when your skin and hair needs extra attention: Monsoon is the favourite season for Bollywood and most of India. Though the rains provide respite from the dreaded summer heat some people fear the monsoons because it may cause hair damage. The weather is sticky, there's too much humidity in the air and as a result, there's an increase in hair-fall/ hair breakage too. Therefore, in monsoons hair care is essential.</p>
                    <p>Rains and  excess humidity in the air can cause injury to the hair as it makes them sticky. This increases the probability of dandruff and hair fall/ hair breakage. In fact, scalp hair is at its weakest condition during monsoon season.</p>
                    <p>For starters, use a wide-toothed comb to comb hair to avoid damaging your hair. Use a brush only when you need to blow dry wet hair. Avoid strongly  combing wet or frizzy hair.</p>
              <p>Let them air dry initially. Gently loosen the knots by running your fingers through your hair. You could comb them out when unentangled.</p>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='monsoonHairCare.png' alt=''></img>
                    </div>
                </div>

               <p>Protect your hair from the rain. Rainwater is dirty and acidic (pH -5.6) which can be harmful for your hair. Wet hair is most brittle when  wet. Avoid going out in the rain or use a rain coat or a windcheater with a good head cover protection. Oil your hair before going out for a rain dance and immediately after shampoo + condition your hair Use a soft cotton/ fibre towel that absorbs water rapidly that reduces friction between the hair and the towel.</p>
              <p>People with sensitive skin or atopic skin often get scalp allergies as a consequence of the epidermis coming into contact with these allergens. Scratching can damage the skin and the hair. Use appropriate precautions and medical management in consultation with a dermatologist. Book an appointment with the best dermatologist in Hyderabad .</p>
              <p>Dandruff can accentuate during monsoons, because of the increased humidity. Avoid sharing combs to avoid fungal infection. Use a medicated shampoo containing ZPTO or ketoconazole as advised by your dermatologist.</p>
              <p>It is recommended to wash your hair more frequently during the rainy season. Also, make sure to not use  too hot  water for hair washing . Applying coconut oil 15 minutes before shampoo helps in preconditioning of your hair. Oil makes the hair greasy and reduces the absorption of water by the hair while washing.</p>
              <p>Diet plays a major role in keeping hair healthy. Add protein-rich foods- fish, eggs, walnuts, cheese and pulses to your diet. In addition fruits and vegetables  provide shine to the hair.</p>
              <p>Anti oxidants also help in making your hair and scalp skin healthy.</p>
              <p>Successful hair fall control depends more on prevention rather than cure.</p>
              <p>Thus, the above hair care tips can be used during monsoons to keep your hair healthy, safe, and glossy. With proper hair care, you can enjoy the rains to the maximum.</p>
              
            </div>
   </>
  )
}

export default Hair_care_Monsoon