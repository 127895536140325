import React from 'react'
import { Link } from 'react-router-dom'


const Laser_Hair_Removal_Benefits_and_Side_Effects = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Monsoon Skin and Hair Care Tips</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <p>Laser hair removal is a popular cosmetic procedure that offers a long-term solution to unwanted hair. It involves using concentrated light beams (lasers) to target and destroy hair follicles, reducing hair growth over time. While it has many benefits, there are also potential side effects to consider.</p>
                        <h3 style={{ fontSize: '20px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Benefits of Laser Hair Removal</span>
                        </h3>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Long-Lasting Results</span>
                        </h4 >
                        <ul>
                            <li>Unlike shaving or waxing, laser hair removal can offer a more permanent reduction in hair growth. After several sessions, many people experience a significant reduction in hair, and in some cases, the hair may not grow back at all.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Precision</span>
                        </h4 >
                        <ul>
                            <li>The laser can selectively target dark, coarse hairs while leaving the surrounding skin undamaged. This makes it highly effective for areas like the face, bikini line, and underarms.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Speed</span>
                        </h4 >
                        <ul>
                            <li>Each pulse of the laser takes a fraction of a second and can treat multiple hairs at once. Small areas like the upper lip can be treated in minutes, while larger areas, like the back or legs, might take an hour or less.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Cost-Effective Over Time</span>
                        </h4 >
                        <ul>
                            <li>Although the upfront cost can be higher than other hair removal methods, laser hair removal can save money in the long run by reducing or eliminating the need for regular shaving, waxing, or depilatory treatments.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Reduced Ingrown Hairs</span>
                        </h4 >
                        <ul>
                            <li>Laser hair removal can reduce the occurrence of ingrown hairs, which are common with shaving or waxing, especially in sensitive areas.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Smooth Skin</span>
                        </h4 >
                        <ul>
                            <li>The procedure can leave the skin feeling smoother and more even, as there’s no stubble or hair regrowth between treatments.</li>
                        </ul>


                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='laser-hair-removal-2x.png' alt=''></img>
                    </div>
                </div>
                <h3 style={{ fontSize: '20px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>Side Effects of Laser Hair Removal</span>
                </h3>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Skin Irritation</span>
                </h4 >
                <ul>
                    <li>Temporary discomfort, redness, and swelling are common after laser hair removal. These symptoms typically disappear within a few hours.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Pigment Changes</span>
                </h4 >
                <ul>
                    <li>The treatment might cause temporary changes in skin color. Those with lighter skin may experience dark spots, while those with darker skin may see lighter spots. These changes usually fade over time.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Mild Pain or Discomfort</span>
                </h4 >
                <ul>
                    <li>Some people may experience a mild stinging sensation during the procedure, often described as the snap of a rubber band against the skin. This discomfort can be minimized with cooling gels or numbing creams.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Crusting</span>
                </h4 >
                <ul>
                    <li>The treated area may develop a crusting effect, which can be uncomfortable but is usually temporary. Proper skincare can help manage this side effect.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Blisters and Burns</span>
                </h4 >
                <ul>
                    <li>Though rare, blisters and burns can occur, especially if the procedure is performed by someone who is not properly trained. These side effects are more likely with darker skin tones.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Eye Injury</span>
                </h4 >
                <ul>
                    <li>Since laser hair removal involves using powerful lasers, there is a risk of eye injury if proper eye protection is not used during treatment. Both the patient and technician should wear protective eyewear.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Risk of Infection</span>
                </h4 >
                <ul>
                    <li>If the treated area is not properly cared for post-treatment, there is a slight risk of infection. Keeping the area clean and avoiding sun exposure can minimize this risk.</li>
                </ul>
                <h3 style={{ fontSize: '20px !important', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#1f1f81" }}>Precautions</span>
                </h3>
                <ul>
                    <li><b>Skin Type Consideration: </b>Laser hair removal works best on people with light skin and dark hair. Those with darker skin tones or lighter hair may need specialized laser treatments.</li>
                    <li><b>Multiple Sessions Required: </b>For optimal results, multiple sessions are necessary, typically spaced 4 to 6 weeks apart. Hair grows in cycles, and the laser is most effective during the growth phase.</li>
                    <li><b>Sun Protection: </b>Avoid sun exposure before and after treatment. Tanned skin can increase the risk of side effects like burns and pigment changes.</li>
                </ul>



            </div>
        </>
    )
}

export default Laser_Hair_Removal_Benefits_and_Side_Effects