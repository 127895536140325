import React from 'react'
import { Link } from 'react-router-dom';

const Skin_Whitening_Treatment = () => {
    return (
        
            <>
                <div style={{ height: '120px' }}></div>
                <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                    <div className="col text-center strip1" style={{ color: 'white' }}>
                        <div className="row mt-5" >
                            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Skin Whitening</h1>
                        </div>
                        <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                    <div className="row">
                        <div className="col-lg-7 mb-2">
                            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                                <span style={{ color: "#1f1f81" }}>Skin Whitening Treatment</span>
                            </h1>
                            <p style={{ textAlign: 'justify' }}>
                                Welcome to Netis Skin and Hair Clinic, where your journey to radiant, flawless skin begins. Dr. Sai Prasanth Neti, a renowned expert in dermatology, brings you cutting-edge skin whitening treatments tailored to your unique needs. With the latest advancements in technology and a commitment to safety and efficacy, our clinic is your trusted partner in achieving the complexion you desire.
                            </p>
                            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                                <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Skin Whitening?</span>
                            </h4 >
                            <ul>
                                <li><b>Expertise You Can Trust: </b> Dr. Sai Prasanth Neti is a seasoned dermatologist with a wealth of experience in cosmetic dermatology. His expertise and dedication have garnered him a reputation as a leader in the field.</li>
                                <li><b>Personalized Treatment Plans: </b>  We understand that every skin type is different. That's why we offer customized treatment plans that are designed to address your specific concerns and deliver results that exceed your expectations.</li>
                            </ul>
                        </div>
                        <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Skin lightening.jpg' alt=''></img>
                        </div>
                    </div>
                    <ul>
                        <li><b>Advanced Technology:</b> At Netis Skin and Hair Clinic, we leverage state-of-the-art technology to ensure that you receive the highest quality care. Our advanced treatments are safe, effective, and designed to minimize discomfort and downtime.</li>
                    </ul>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "#fecb3e" }}>Our Skin Whitening Treatments</span>
                    </h4 >
                    <ul style={{listStyleType:'none'}}>
                        <li style={{textDecoration:'none'}}><b>Laser Skin Whitening: </b> Experience the power of laser technology in achieving a lighter, more even complexion. Our advanced laser treatments target melanin-producing cells, effectively lightening dark spots and pigmentation.</li>
                        <li><b>Chemical Peels: </b> Reveal brighter, smoother skin with our range of chemical peels. These treatments gently exfoliate the top layer of your skin, reducing pigmentation, fine lines, and sun damage.</li>
                        <li><b>Microdermabrasion: </b> This non-invasive procedure uses a gentle abrasive technique to remove dead skin cells, promoting cell turnover and a more youthful appearance. It's an excellent option for those seeking a brighter complexion.</li>
                        <li><b>Topical Treatments: </b> Our clinic offers a range of specialized skin whitening creams and serums that are formulated with potent ingredients to target hyperpigmentation and promote even skin tone.</li>
                    </ul>
                    <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                        <span style={{ color: "#fecb3e" }}>Your Journey to Radiant Skin Begins Here</span>
                    </h4 >
                    <p style={{ textAlign: 'justify' }}>
                    At Netis Skin and Hair Clinic, we are dedicated to helping you achieve the skin you've always dreamed of. Our commitment to excellence, combined with the latest technology and Dr. Sai Prasanth Neti's expertise, sets us apart as a leading destination for skin whitening treatments in Hyderabad.
                    </p>
                   <p style={{ textAlign: 'justify' }}>Book your consultation today and embark on a transformative journey towards brighter, more beautiful skin. Your radiant future awaits!</p>
                </div>

            </>
        
    )
}

export default Skin_Whitening_Treatment