import React, { useState } from 'react'

import Carousel from 'react-bootstrap/Carousel';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// Import Swiper styles

import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Testmonialss = () => {

  const [more, setMore] = useState(false);
  const clickHandler = () => {
    setMore(!more);
  }


  const [more1, setMore1] = useState(false);
  const clickHandler1 = () => {
    setMore1(!more1);
  }

  const [more2, setMore2] = useState(false);
  const clickHandler2 = () => {
    setMore2(!more2);
  }

  const [more3, setMore3] = useState(false);
  const clickHandler3 = () => {
    setMore3(!more3);
  }

  const [more4, setMore4] = useState(false);
  const clickHandler4 = () => {
    setMore4(!more4);
  }

  const [more5, setMore5] = useState(false);
  const clickHandler5 = () => {
    setMore5(!more5);
  }


  return (
    <>

      {/* <Carousel data-bs-theme="light" indicators={false} controls={false} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Carousel.Item>

          

          <div className='container card-wrapper'>
            <Card className="carded" >
              
            <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>K</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>K Teju</b></Card.Title>
                <Card.Text className='mt-2'>
                  I was impressed with his expertise and compassionate approach. He prescribed me medication which was very effective, and I was able to see results in just one week. Dr. Neti's way of treating patients is admirable, he listens patiently to all their concerns and explains everything in detail. He made me feel comfortable throughout the treatment process, and I am grateful for the excellent care I received. I highly recommend Dr. Neti to anyone in need . Thank you, Dr. Neti,                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>G</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Guruswamy Gosela</b></Card.Title>
                <Card.Text className='mt-2'>
                  Hi all, my self Guruswamy. I was suffering pain on right big toe for last couple of months and went to number of hospitals for the treatment but I got only medicine which were not cured my problem.
                  But I met Dr. Sai Prashant and I got the clear explanation about my problem and went for  the nail surgery with out knowing pain.

                  I am really thank you Dr.Sai Prashant sir.

                  I really recommend for any issues related to nails, face and hair.

                  Thanks..                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>P</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Priyanshi Jha</b></Card.Title>
                <Card.Text className='mt-2'>
                  Highly skilled Doctor.
                  He found root cause of issue and identified best medicine which helped me to recover in 2 weeks. Struggled for 6 months with several medicines without any recovery but
                  dr. Sai Prasanth is very skilled and talented and diagnosed very well.
                  Good interaction and understanding of problem. Highly preferred and most qualified dermatologist in Hyderabad.                </Card.Text>

              </Card.Body>
            </Card>
          </div>

        </Carousel.Item>
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>S</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Shreeya Gupta</b></Card.Title>
                <Card.Text className='mt-2'>
                  His diagnosis is great as well as his treatment is really effective... I have been on his medication for six months and  I saw the effect in the first month itself still I waited for this long to write a review... I am really happy with his service                  </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>Y</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>yasmin ashu</b></Card.Title>
                <Card.Text className='mt-2'>
                  Very good experience.. Got Speedy recovery... Excellent Doctor... Having lot of patience..                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>D</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Dasari Koteshwari</b></Card.Title>
                <Card.Text className='mt-2'>
                  I had a wonderful and pleasant experience! I was struggling with acne and pigmentation on my face, but after undergoing treatment, I noticed positive changes in my skin.
                </Card.Text>

              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='container card-wrapper'>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>S</b></Card.Title>
              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Surendra Kumar</b></Card.Title>
                <Card.Text className='mt-2'>
                  I recently visited Dr. Neti at his practice in Ameerpet, Hyderabad for treatment of chicken pox. I was impressed with his expertise and compassionate approach...</Card.Text>

                  
                 {more&&
                  <div>
                  <Card.Text style={{marginTop:'-15px'}}>He prescribed me medication which was very effective, and I was able to see results in just one week.Dr. Neti's way of treating patients is admirable, he listens patiently to all their concerns and explains everything in detail. He made me feel comfortable throughout the treatment process, and I am grateful for the excellent care I received. I highly recommend Dr. Neti to anyone in need of a skilled and caring skin specialist. Thank you, Dr. Neti, for helping me recover from chicken pox so quickly."I hope this review helps you in your goal of providing feedback for Dr. Neti. Let me know if you need any further assistance!             </Card.Text>
                  
                  </div>}

                  <span className='mt-2' style={{backgroundColor:'#fecb3e',padding:'2px'}} onClick={clickHandler}>{more?'Read Less':'Read More'}</span>
                 
              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>P</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Pranay kumar</b></Card.Title>
                <Card.Text className='mt-2'>
                  Excellent professional shown by the doctor 👏 and support staff.The doctor is too good with her treatment. Thank you Dr. This is the best skin and hair clinic in Hyderabad. Highly recommended  👏 💯                </Card.Text>

              </Card.Body>
            </Card>
            <Card className="carded" >
                        <Card.Title className='text-center mt-3' style={{backgroundColor:'#1f1f81' ,color:'white',marginLeft:'auto',marginRight:'auto',borderRadius:'50%',width:'50px',height:'50px',fontSize:'30px',paddingTop:'5px'}}><b>S</b></Card.Title>

              <Card.Body>
                <Card.Title className='text-center' style={{marginTop:'-10px'}}><b>Sekhar</b></Card.Title>
                <Card.Text className='mt-2'>
                  Best dermatologyst in Hyderabad, Dr Prashant neti garu he will explain solutions clearly with best suggestions with reasonable prices                </Card.Text>

              </Card.Body>
            </Card>
          </div>
        </Carousel.Item>
      </Carousel> */}

      <div className='container swip swiper mt-5 ' >
        <Swiper
          modules={[Navigation, Pagination, Scrollbar]}
          spaceBetween={50}
          slidesPerView={4}

          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          breakpoints={{
            320: {
              slidesPerView: 1,

              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            }
          }}
        >
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>K</b></p>
              <h4 className='text-center'>K Teju</h4>
              <p style={{ textAlign: 'justify' }}>I was impressed with his expertise and compassionate approach. He prescribed me medication which was very effective, and I was able to see results in just one week.</p>

              {more && <p style={{ textAlign: 'justify', marginTop: '-15px' }}> Dr. Neti's way of treating patients is admirable, he listens patiently to all their concerns and explains everything in detail.He made me feel comfortable throughout the treatment process, and I am grateful for the excellent care I received. I highly recommend Dr. Neti to anyone in need . Thank you, Dr. Neti,
              </p>}
              <div className='text-end'>
              <span className='p-2' onClick={clickHandler} style={{ background: '#fecb3e'}}>{more ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>


          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>G</b></p>
              <h4 className='text-center'>Guruswamy Gosela</h4>
              <p style={{ textAlign: 'justify' }}>Hi all, my self Guruswamy. I was suffering pain on right big toe for last couple of months and went to number of hospitals for the treatment but I got only medicine which were not cured my problem.</p>

              {more1 && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>But I met Dr. Sai Prashant and I got the clear explanation about my problem and went for  the nail surgery with out knowing pain.

                I am really thank you Dr.Sai Prashant sir.

                I really recommend for any issues related to nails, face and hair.
              </p>}
              <div className='text-end' >
              <span className='p-2' onClick={clickHandler1} style={{ background: '#fecb3e' }}>{more1 ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>S</b></p>
              <h4 className='text-center'>Shreeya Gupta</h4>
              <p style={{ textAlign: 'justify' }}>His diagnosis is great as well as his treatment is really effective... I have been on his medication for six months and  I saw the effect in the first month itself still I waited for this long to write a review... I am really happy with his service  </p>

              {more && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>
              </p>}
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>P</b></p>
              <h4 className='text-center'>Priyanshi Jha</h4>
              <p style={{ textAlign: 'justify' }}>Highly skilled Doctor.
                He found root cause of issue and identified best medicine which helped me to recover in 2 weeks. Struggled for 6 months with several medicines without any …</p>

              {more2 && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>recovery but
                dr. Sai Prasanth is very skilled and talented and diagnosed very well.
                Good interaction and understanding of problem. Highly preferred and most qualified dermatologist in Hyderabad.
              </p>}
              <div className='text-end' >
              <span className='p-2' onClick={clickHandler2} style={{ background: '#fecb3e' }}>{more2 ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>Y</b></p>
              <h4 className='text-center'>yasmin ashu</h4>
              <p style={{ textAlign: 'justify' }}>Very good experience.. Got Speedy recovery... Excellent Doctor... Having lot of patience..
              </p>

              {more && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>
              </p>}
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>D</b></p>
              <h4 className='text-center'>Dasari Koteshwari</h4>
              <p style={{ textAlign: 'justify' }}>I had a wonderful and pleasant experience! I was struggling with acne and pigmentation on my face, but after undergoing treatment, I noticed positive changes in my skin.</p>

              {more3 && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>
              </p>}
              <div className='text-end' >
              <span className='p-2' onClick={clickHandler3} style={{ background: '#fecb3e' }}>{more3 ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>S</b></p>
              <h4 className='text-center'>Surendra Kumar</h4>
              <p style={{ textAlign: 'justify' }}>I recently visited Dr. Neti at his practice in Ameerpet, Hyderabad for treatment of chicken pox. I was impressed with his expertise and compassionate approach. He prescribed me medication which was very effective, and I was able to see results in just</p>

              {more4 && <p style={{ textAlign: 'justify', marginTop: '-15px' }}> one week. Dr. Neti's way of treating patients is admirable, he listens patiently to all their concerns and explains everything in detail. He made me feel comfortable throughout the treatment process, and I am grateful for the excellent care I received. I highly recommend Dr. Neti to anyone in need of a skilled and caring skin specialist. Thank you, Dr. Neti, for helping me recover from chicken pox so quickly."

                I hope this review helps you in your goal of providing feedback for Dr. Neti. Let me know if you need any further assistance!
              </p>}
              <div className='text-end' >
              <span className='p-2' onClick={clickHandler4} style={{ background: '#fecb3e' }}>{more4 ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>


          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>P</b></p>
              <h4 className='text-center'>Pranay kumar</h4>
              <p style={{ textAlign: 'justify' }}>Excellent professional shown by the doctor 👏 and support staff.The doctor is too good with her treatment. Thank you Dr. This is the best skin and hair clinic in Hyderabad. Highly recommended  👏 💯</p>

              {more && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>
              </p>}
            </div>
          </SwiperSlide>
          <SwiperSlide className='mb-5'>
            <div className='p-3' style={{ border: '1px solid black' }}>
              <p className='text-center mt-3' style={{ backgroundColor: '#1f1f81', color: 'white', marginLeft: 'auto', marginRight: 'auto', borderRadius: '50%', width: '50px', height: '50px', fontSize: '30px', }}><b>S</b></p>
              <h4 className='text-center'>Sekhar</h4>
              <p style={{ textAlign: 'justify' }}>Best dermatologyst in Hyderabad, Dr Prashant neti garu he will explain solutions clearly with best suggestions with reasonable prices</p>

              {more5 && <p style={{ textAlign: 'justify', marginTop: '-15px' }}>
              </p>}
              <div className='text-end' >
              <span className='p-2' onClick={clickHandler5} style={{ background: '#fecb3e' }}>{more5 ? 'Read Less' : 'Read More'}</span>
              </div>
            </div>
          </SwiperSlide>

        </Swiper>
      </div>




    </>
  )
}

export default Testmonialss