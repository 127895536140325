import React from 'react'
import { Link } from 'react-router-dom'


const MNRF_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Microneedle Radiofrequency(MNRF) Treatments</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important',  color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Microneedle Radiofrequency(MNRF) Treatments</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        Welcome to Netis Skin and Hair Clinic, where we offer cutting-edge MNRF (Microneedle Radiofrequency) treatments for skin rejuvenation. Under the expert guidance of Dr. Sai Prasanth Neti, our clinic combines advanced technology with a personalized approach to help you achieve youthful and radiant skin.
                            </p>
                            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for MNRF Treatments?</span>
                        </h4 >
                     
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='MNRF-Treatment-1.jpg' alt=''></img>
                    </div>
                </div>
                

                        <p style={{ textAlign: 'justify' }}><b>Distinguished Dermatological Expertise: </b>Dr. Sai Prasanth Neti is a renowned dermatologist known for his proficiency in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized Treatment Plans: </b>We understand that each individual's skin is unique. Our MNRF treatments are tailored to address specific concerns, ensuring optimal results.</p>

                <p style={{ textAlign: 'justify' }}><b>Advanced Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our MNRF procedures utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About MNRF Treatments</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>MNRF, or Microneedle Radiofrequency, is a non-invasive skin rejuvenation procedure that combines micro-needling with radiofrequency energy. Tiny needles create micro-channels in the skin, allowing for deeper penetration of radiofrequency energy. This stimulates collagen production, resulting in firmer, smoother, and more youthful-looking skin.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of MNRF Treatments:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Improved Skin Texture: </b>Refines pores, reduces fine lines, and smoothens skin texture for a youthful appearance.</p>
                <p style={{ textAlign: 'justify' }}><b>Collagen Stimulation: </b>Promotes the natural production of collagen, leading to firmer, more resilient skin.</p>
                <p style={{ textAlign: 'justify' }}><b>Minimal Downtime: </b>Non-surgical procedure with minimal downtime, allowing for a quick return to regular activities.</p>
              

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant, Youthful Skin</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the transformative power of healthy, glowing skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the complexion you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more youthful appearance. Schedule your consultation today, and let us guide you towards the radiant, revitalized complexion you deserve.</p>
                

            </div>

        </>
    )
}

export default MNRF_Treatment