import React from 'react'
import { Link } from 'react-router-dom'

const Gallery = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Gallery</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>
            <div className='container'>
                <div className='row mt-5'>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='EDIT.png' alt='image1' style={{ width: '100%' }}></img>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9974.JPG' alt='image4' style={{ width: '100%' }}></img>
                    </div>
                    
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4'>

                        <img src='IMG_9975.JPG' alt='image5' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9978.JPG' alt='image8' style={{ width: '100%' }}></img>
                    </div>

                </div>

                <div className='row mt-5'>

                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9976.JPG' alt='image6' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9977.JPG' alt='image7' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9984.JPG' alt='image12' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9973.JPG' alt='image3' style={{ width: '100%' }}></img>
                    </div>
                 

                </div>

                <div className='row mt-5'>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4'>

                        <img src='IMG_9981.JPG' alt='image9' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9982.JPG' alt='image10' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9983.JPG' alt='image11' style={{ width: '100%' }}></img>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9993.JPG' alt='image13' style={{ width: '100%' }}></img>
                    </div>


                </div>

                <div className='row mt-5'>
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4'>

                        <img src='IMG_9986.JPG' alt='image13' style={{ width: '100%' }}></img>
                    </div>
                   
                    <div className='col-lg-3 col-md-4 col-sm-12 mt-3 mb-4 '>
                        <img src='IMG_9972.JPG' alt='image2' style={{ width: '100%' }}></img>
                    </div>
                   
                    
                </div>

            </div>


        </>
    )
}

export default Gallery