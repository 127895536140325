import React from 'react'
import { Link } from 'react-router-dom'

const Corn_Wart_Skin_Tag_Removal_by_RF = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Corn /Wart /Skin Tag Removal by RF</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Corn /Wart /Skin Tag Removal by RF</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Welcome to Netis Skin and Hair Clinic, where we specialize in advanced RF (Radiofrequency) treatments for the effective removal of corns, warts, and skin tags. Under the expert guidance of Dr. Sai Prasanth Neti, our clinic combines cutting-edge technology with a personalized approach to ensure safe and efficient removal procedures.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for RF Treatment?</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}><b>Dermatological Expertise: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his proficiency in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>

          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Corn wart skin removel.jpeg' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}><b>Precise and Non-Invasive: </b>Our RF treatment offers a non-invasive solution for the removal of corns, warts, and skin tags. It is known for its precision in targeting specific areas, ensuring minimal impact on surrounding healthy tissue.</p>
        <p style={{ textAlign: 'justify' }}><b>Advanced RF Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our RF treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>About RF Treatment for Corns, Warts, and Skin Tags</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>RF treatment uses radiofrequency energy to safely and effectively remove unwanted skin growths such as corns, warts, and skin tags. The energy heats the tissue, causing it to break down and eventually be naturally eliminated by the body. This process results in the removal of the growth with minimal scarring.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Key Benefits of RF Treatment:</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}><b>Precise and Controlled: </b>Targets the specific area of concern with minimal impact on surrounding healthy tissue.</p>
        <p style={{ textAlign: 'justify' }}><b>Minimally Invasive: </b>Non-surgical procedure with little to no downtime, allowing for a quick return to regular activities.</p>
        <p style={{ textAlign: 'justify' }}><b>Minimal Scarring: </b>Leaves behind minimal scarring compared to traditional surgical methods.</p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Clear, Smooth Skin</span>
        </h4 >

        <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that corns, warts, and skin tags can have on your comfort and confidence. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the clear, smooth skin you desire.</p>
        <p style={{ textAlign: 'justify' }}>Begin your journey towards a brighter, more comfortable future. Schedule your consultation today, and let us guide you towards the clear, healthy complexion you deserve.</p>


      </div>

    </>

    // <>
    // <div style={{ height: '120px' }}></div>
    // <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
    //   <div className="col text-center strip1" style={{ color: 'white' }}>
    //     <div className="row mt-5" >
    //       <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>PRP For Face And Hair</h1>
    //     </div>
    //     <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
    //       <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
    //     </ul>
    //   </div>
    // </div>

    // <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
    //   <div className="row">
    //     <div className="col-lg-7 mb-2">
    //       <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
    //         <span style={{ color: "#1f1f81" }}>PRP For Face And Hair</span>
    //       </h1>
    //       <p style={{ textAlign: 'justify' }}>
    //       </p>
    //       <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //         <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for PRP Treatment?</span>
    //       </h4 >

    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>
    //       <p style={{ textAlign: 'justify' }}><b></p>


    //     </div>
    //     <div className="col-lg-5 servicesimg">
    //       <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='down 2.png' alt=''></img>
    //     </div>
    //   </div>

    //   <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //     <span style={{ color: "#fecb3e" }}>About PRP For Face And Hair Treatment</span>
    //   </h4 >
    //   <p style={{ textAlign: 'justify' }}>PRP therapy involves the extraction and concentration of growth factors from your own blood. These growth factors play a crucial role in cell renewal and tissue repair. When applied to the skin or scalp, they stimulate collagen production, improve skin texture, and promote hair growth.</p>

    //   <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //     <span style={{ color: "#fecb3e" }}>Key Benefits of PRP Treatment:</span>
    //   </h4 >

    //   <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //     <span style={{ color: "#1f1f81" }}>For the Face:</span>
    //   </h5 >
    //   <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //     <span style={{ color: "#1f1f81" }}>For the Hair:</span>
    //   </h5 >


    //   <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
    //     <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Radiant Skin and Luscious Hair</span>
    //   </h4 >


    // </div>

    // </>
  )
}

export default Corn_Wart_Skin_Tag_Removal_by_RF