import React from 'react'
import { Link } from 'react-router-dom'

const Ten_Best_Monsoon_Hair_Care_Tips = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>10 Best Monsoon Hair Care Tips</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-3 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <p>Here are ten of the best hair care tips to help maintain healthy and beautiful hair during the monsoon season:</p>
            
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Regular Washing:</span>
            </h4 >
            <ul>
              <li>Frequency: Wash your hair 2-3 times a week to keep your scalp clean and free from excess oil and sweat.</li>
              <li>Shampoo Choice: Use a mild, sulfate-free shampoo to avoid stripping natural oils from your hair.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Proper Drying:</span>
            </h4 >
            <ul>
              <li>Natural Drying: Allow your hair to dry naturally as much as possible. Avoid tying up wet hair to prevent fungal infections.</li>
              <li>Blow Dryer Use: If necessary, use a blow dryer on a cool setting to dry your hair.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Conditioning:</span>
            </h4 >
            <ul>
              <li>Regular Use: Apply a lightweight conditioner after every wash to keep your hair manageable and prevent frizz.</li>
              <li>Focus Areas: Concentrate on the ends of your hair to avoid weighing down the roots.</li>
            </ul>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Anti-Frizz Products:</span>
            </h4 >
            <ul>
              <li>Serums and Creams: Use anti-frizz serums or leave-in conditioners to control frizz and add shine to your hair.</li>
              <li>Natural Oils: Apply a few drops of natural oils like argan or jojoba oil to tame frizz.</li>
            </ul>
          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '250px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='hair care Tips.avif' alt=''></img>
          </div>
        </div>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Oil Massages:</span>
        </h4 >
        <ul>
          <li>Scalp Health: Regularly massage your scalp with light oils like coconut, almond, or olive oil to nourish your scalp and hair.</li>
          <li>Application: Leave the oil on for at least an hour before washing it off.</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Avoid Heat Styling:</span>
        </h4 >
        <ul>
          <li>Minimize Damage: Reduce the use of heat styling tools like straighteners and curling irons to prevent dryness and breakage.</li>
          <li>Natural Styles: Embrace natural hairstyles that do not require heat.</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Hair Masks:</span>
        </h4 >
        <ul>
          <li>Deep Conditioning: Use a deep conditioning hair mask once a week to nourish and strengthen your hair.</li>
          <li>Ingredients: Look for masks with natural ingredients like avocado, honey, or yogurt.</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Trimming:</span>
        </h4 >
        <ul>
          <li>Regular Cuts: Get regular trims to get rid of split ends and keep your hair healthy.</li>
          <li>Prevent Breakage: Trimming helps in reducing breakage and promotes healthy growth.</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Diet and Hydration:</span>
        </h4 >
        <ul>
          <li>Balanced Diet: Consume a diet rich in vitamins and minerals, including fruits, vegetables, nuts, and seeds, to promote healthy hair growth.</li>
          <li>Water Intake: Drink plenty of water to keep your hair hydrated from within.</li>
        </ul>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Protective Hairstyles:</span>
        </h4 >
        <ul>
          <li>Minimize Exposure: Use protective hairstyles like braids or buns to minimize hair exposure to humidity and rain.</li>
          <li>Secure Styling: Avoid tight hairstyles that can cause breakage and stress on your hair.</li>
        </ul>
        <p>By following these tips, you can maintain the health and beauty of your hair during the monsoon season.</p>

      </div>

    </>
  )
}

export default Ten_Best_Monsoon_Hair_Care_Tips