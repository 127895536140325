import React from 'react'
import { Link } from 'react-router-dom'


const Laser_Tattoo_Removal = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Laser Tattoo Removal treatments</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Laser Tattoo Removal</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Welcome to Netis Skin and Hair Clinic, where we specialize in helping you achieve clear, tattoo-free skin. Led by the esteemed Dr. Sai Prasanth Neti, our clinic offers cutting-edge Laser Tattoo Removal treatments. With a focus on advanced technology and a commitment to delivering exceptional results, we stand as a beacon of hope for those seeking to erase unwanted tattoos.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Laser Tattoo Removal?</span>
            </h4 >

            <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skin care. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>

          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='laser tattoo removel.jpg' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our Laser Tattoo Removal treatments utilize state-of-the-art technology, ensuring both efficacy and comfort for our patients.</p>
        <p style={{ textAlign: 'justify' }}><b>Customized Approach: </b>Recognizing that each tattoo and skin type is unique, we create personalized treatment plans to address your specific concerns. Our goal is to reveal clear, tattoo-free skin tailored to your needs.</p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>About Laser Tattoo Removal</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>Laser Tattoo Removal is a non-invasive procedure that uses advanced laser technology to break down the ink particles in a tattoo. The laser targets the pigments, causing them to fragment and be gradually absorbed by the body. This process allows for the gradual fading and eventual complete removal of the tattoo.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Key Benefits of Laser Tattoo Removal:</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}><b>Safe and Effective: </b>Advanced technology ensures precise targeting of tattoo pigments without causing harm to surrounding skin.</p>
        <p style={{ textAlign: 'justify' }}><b>Minimal Discomfort: </b>The procedure is well-tolerated by most patients, and numbing options are available for increased comfort.</p>
        <p style={{ textAlign: 'justify' }}><b>Complete Removal: </b>With multiple sessions, even multi-colored or intricate tattoos can be effectively removed.</p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Tattoo-Free Skin</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the importance of feeling comfortable and confident in your own skin. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the clear, tattoo-free skin you desire.</p>
        <p style={{ textAlign: 'justify' }}>Begin your journey towards a tattoo-free future. Schedule your consultation today, and let us guide you towards the clear, radiant complexion you deserve.</p>


      </div>

    </>
  )
}

export default Laser_Tattoo_Removal