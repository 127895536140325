import './App.css';
import Header from './Header';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ScrollToTop from "react-scroll-to-top";
import './App.css';
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';
import Netis_Skin_And_Hair_Clinic_Products from './Netis_Skin_And_Hair_Clinic_Products';
import Skin_Whitening_Treatment from './Skin_Whitening_Treatment';
import Acne_And_Acne_Scars_Treatment from './Acne_And_Acne_Scars_Treatment';
import Pigmentation_Treatment from './Pigmentation_Treatment';
import Chemical_Peel_Treatment from './Chemical_Peel_Treatment';
import Hydradermabrasion_Treatment from './Hydradermabrasion_Treatment';
import Skin_Rejuvenation from './Skin_Rejuvenation';
import Stretch_Marks_Treatment from './Stretch_Marks_Treatment';
import Carbon_Toning from './Carbon_Toning';
import Laser_Hair_Removal from './Laser_Hair_Removal';
import Laser_Tattoo_Removal from './Laser_Tattoo_Removal';
import Wrinkles_Treatment from './Wrinkles_Treatment';
import Growth_factor_concentrate_therapy from './Growth_factor_concentrate_therapy';
import Prp_For_Face_And_Hair from './Prp_For_Face_And_Hair';
import Corn_Wart_Skin_Tag_Removal_by_RF from './Corn_Wart_Skin_Tag_Removal_by_RF';
import Permanent_Eyebrows from './Permanent_Eyebrows';
import Laser_Toning from './Laser_Toning';
import Nail_Surgery from './Nail_Surgery';
import Earlobe_Repair from './Earlobe_Repair';
import Antiaging_Treatments from './Antiaging_Treatments';
import Hair_Transplantation from './Hair_Transplantation';
import Scalp_Micropigmentation from './Scalp_Micropigmentation';
import Vitiligo_Surgeries from './Vitiligo_Surgeries';
import Dr_Sai_Prasanth_Neti from './Dr_Sai_Prasanth_Neti';
import MNRF_Treatment from './MNRF_Treatment';
import Blogs from './Blogs';
import Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments from './Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments';
import MNRF_Treatment_In_Hyderabad from './MNRF_Treatment_In_Hyderabad';
import How_To_Take_Skin_Care_In_Winter from './How_To_Take_Skin_Care_In_Winter';
import Hair_loss_alopecia_in_men from './Hair_loss_alopecia_in_men';
import Hair_care_Monsoon from './Hair_care_Monsoon';
import Laser_treatment_options_your_skin_and_hair from './Laser_treatment_options_your_skin_and_hair';
import Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist from './Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist';
import Cosmelan_Peel_Treatment from './Cosmelan_Peel_Treatment';
import Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis from './Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis';
import Gallery from './Gallery';
import Benefits_of_gfc_hair_treatment_for_ultimate_hair_care from './Benefits_of_gfc_hair_treatment_for_ultimate_hair_care';
import PRP_Treatment_In_Hyderabad from './PRP_Treatment_In_Hyderabad';
import Skin_Pigmentation from './Skin_Pigmentation';
import HyperPigmentation from './HyperPigmentation';
import Summer_skin_care_tips from './summer_skin_care_tips';
import Top_10_Common_Summer_Skin_and_Hair_Problems_preventions_and_Solutions from './Top_10_Common_Summer_Skin_and_Hair_Problems';
import Haircare_tips_for_summer_season from './haircare_tips_for_summer_season';
import MNRF_for_Acne_Scar_Treatment_in_Hyderabad from './MNRF_for_Acne_Scar_Treatment_in_Hyderabad';
import MNRF_Treatment_Cost_in_Hyderabad from './MNRF_Treatment_Cost_in_Hyderabad';
import Monsoon_Skin_and_Hair_Care_Tips from './Monsoon_Skin_and_Hair_Care_Tips';
import Ten_Best_Monsoon_Hair_Care_Tips from './10_Best_Monsoon_Hair_Care_Tips';
import Eight_Best_Hair_Growth_Supplements_That_Youre_Unaware_Of from './8_Best_Hair_Growth_Supplements_That_You’re_Unaware_Of';
import IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT from './IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT';
import Laser_Hair_Removal_Benefits_and_Side_Effects from './Laser_Hair_Removal_Benefits_and_Side_Effects';



function App() {
  return (
    <BrowserRouter>
    <Header />

    <HelmetProvider>
      <Helmet>

        <meta charSet="utf-8" />
        <title>Netis Skin And Hair Clinic</title>
        <meta property="og:title" content="Netis Skin And Hair Clinic" />
        <meta property="og:site_name" content="Netis Skin And Hair Clinic" />          
        <meta property="og:description"
          content="" />
      
        {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
      </Helmet>
    </HelmetProvider>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Dr_Sai_Prasanth_Neti" element={<Dr_Sai_Prasanth_Neti />} />
      <Route path="/Netis_Skin_And_Hair_Clinic_Products" element={<Netis_Skin_And_Hair_Clinic_Products />} />
      <Route path="/Skin_Whitening_Treatment" element={<Skin_Whitening_Treatment/>} />
      <Route path="/Acne_And_Acne_Scars_Treatment" element={<Acne_And_Acne_Scars_Treatment />} />
      <Route path="/Pigmentation_Treatment" element={<Pigmentation_Treatment />} />
      <Route path="/Chemical_Peel_Treatment" element={<Chemical_Peel_Treatment />} />
      <Route path="/Hydradermabrasion_Treatment" element={<Hydradermabrasion_Treatment />} />
      <Route path="/Skin_Rejuvenation" element={<Skin_Rejuvenation />} />
      <Route path="/Stretch_Marks_Treatment" element={<Stretch_Marks_Treatment />} />
      <Route path="/Carbon_Toning" element={<Carbon_Toning/>} />
      <Route path="/Laser_Hair_Removal" element={<Laser_Hair_Removal />} />
      <Route path="/Laser_Tattoo_Removal" element={<Laser_Tattoo_Removal />} />
      <Route path="/Wrinkles_Treatment" element={<Wrinkles_Treatment/>} />
      <Route path="/Growth_factor_concentrate_therapy" element={<Growth_factor_concentrate_therapy/>} />
      <Route path="/Prp_For_Face_And_Hair" element={<Prp_For_Face_And_Hair />} />
      <Route path="/Corn_Wart_Skin_Tag_Removal_by_RF" element={<Corn_Wart_Skin_Tag_Removal_by_RF/>} />
      <Route path="/Permanent_Eyebrows" element={<Permanent_Eyebrows />} />
      <Route path="/Laser_Toning" element={<Laser_Toning />} />
      <Route path="/Nail_Surgery" element={<Nail_Surgery />} />
      <Route path="/Earlobe_Repair" element={<Earlobe_Repair />} />
      <Route path="/Antiaging_Treatments" element={<Antiaging_Treatments />} />
      <Route path="/Hair_Transplantation" element={<Hair_Transplantation />} />
      <Route path="/Scalp_Micropigmentation" element={<Scalp_Micropigmentation />} />
      <Route path="/Vitiligo_Surgeries" element={<Vitiligo_Surgeries />} />
      <Route path="/MNRF_Treatment" element={<MNRF_Treatment />} />
      <Route path="/Blogs" element={<Blogs />} />
      <Route path="/Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments" element={<Acne_And_Pimples_Prevention_Tips_Causes_And_Treatments />} />
      <Route path="/MNRF_Treatment_In_Hyderabad" element={<MNRF_Treatment_In_Hyderabad />} />
      <Route path="/How_To_Take_Skin_Care_In_Winter" element={<How_To_Take_Skin_Care_In_Winter />} />
      <Route path="/Hair_loss_alopecia_in_men" element={<Hair_loss_alopecia_in_men />} />
      <Route path="/Hair_care_Monsoon" element={<Hair_care_Monsoon/>} />
      <Route path="/Laser_treatment_options_your_skin_and_hair" element={<Laser_treatment_options_your_skin_and_hair />} />
      <Route path="/Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist" element={<Dry_Skin_Treatment_and_Prevention_Tips_from_Dermatologist />} />
      <Route path="/Cosmelan_Peel_Treatment" element={<Cosmelan_Peel_Treatment />} />
      <Route path="/Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis" element={<Top_10_Skin_Care_Tips_From_Dr_Sai_Prasanth_Netis />} />
      <Route path='/Gallery' element={<Gallery/>}/>
      <Route path='/Benefits_of_gfc_hair_treatment_for_ultimate_hair_care' element={<Benefits_of_gfc_hair_treatment_for_ultimate_hair_care/>}/>
      <Route path='/PRP_Treatment_In_Hyderabad' element={<PRP_Treatment_In_Hyderabad/>}/>
      <Route path='/Skin_Pigmentation' element={<Skin_Pigmentation/>}/>
      <Route path='/HyperPigmentation' element={<HyperPigmentation/>}/>
      <Route path='/summer_skin_care_tips' element={<Summer_skin_care_tips/>}/>
      <Route path='/Top_10_Common_Summer_Skin_and_Hair_Problems_preventions_and_Solutions' element={<Top_10_Common_Summer_Skin_and_Hair_Problems_preventions_and_Solutions/>}/>
      <Route path='/haircare_tips_for_summer_season' element={<Haircare_tips_for_summer_season/>}/>
      <Route path='/MNRF_for_Acne_Scar_Treatment_in_Hyderabad' element={<MNRF_for_Acne_Scar_Treatment_in_Hyderabad/>}/>
      <Route path='/MNRF_Treatment_Cost_in_Hyderabad' element={<MNRF_Treatment_Cost_in_Hyderabad/>}/>
      <Route path='/Monsoon_Skin_and_Hair_Care_Tips' element={<Monsoon_Skin_and_Hair_Care_Tips/>}/>
      <Route path='/10_Best_Monsoon_Hair_Care_Tips' element={<Ten_Best_Monsoon_Hair_Care_Tips/>}/>
      <Route path='/8_Best_Hair_Growth_Supplements_That_You’re_Unaware_Of' element={<Eight_Best_Hair_Growth_Supplements_That_Youre_Unaware_Of/>}/>
      <Route path='/IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT' element={<IS_HAIR_LOSS_OR_ALOPECIA_STRESSING_YOU_OUT/>}/>
      <Route path='/Laser_Hair_Removal_Benefits_and_Side_Effects' element={<Laser_Hair_Removal_Benefits_and_Side_Effects/>}/>
     </Routes>
{/* 
    <div className='right-navigation'>
      <Link to='Insurance' className='insurance_float'><img className="whatsapp_float" src='insu6.webp' alt=''></img>Insurance</Link>
    </div> */}
    <div className="GoTop"  >
      <ScrollToTop smooth style={{backgroundColor:'#fecb3e',bottom:'10px',width:'35px',height:'35px',color:'white'}} />
    </div>
  <Footer/>
  </BrowserRouter>
  );
}

export default App;
