import React from 'react'
import { Link } from 'react-router-dom'


const Top_10_Common_Summer_Skin_and_Hair_Problems = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-5" >
            <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Top 10 Common Summer Skin And Hair Problems</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
            <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
          </ul>
        </div>
      </div>

      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#1f1f81" }}>Top 10 Common Summer Skin And Hair Problems Prevention and Solutions</span>
            </h3>6
            <p style={{ textAlign: 'justify' }}>
              Summer brings sunshine, beach days, and outdoor adventures, but it also comes with its fair share of skin and hair challenges. Here are 10 common summer skin and hair problems, along with prevention tips and effective solutions to keep you looking and feeling your best all season long.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "#fecb3e" }}>Sunburn :</span>
            </h4 >
            <p><b>Problem :</b> Spending too much time in the sun can lead to painful sunburns.</p>
            <p><b>Prevention :</b>  Apply sunscreen with SPF 30 or higher and seek shade when possible.</p>
            <p><b>Solution :</b>If sunburn occurs, apply aloe vera gel to soothe the skin and drink plenty of water.</p>

          </div>
          <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Summer-Hair-Care.webp' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Heat Rash:</span>
        </h4 >
        <p><b>Problem :</b> Heat and humidity can cause sweat to become trapped in the skin, leading to heat rash.</p>
        <p><b>Prevention :</b> Wear lightweight, breathable clothing.</p>
        <p><b>Solution :</b> Apply a cool compress and avoid scratching the affected area.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Acne Breakouts:</span>
        </h4 >
        <p><b>Problem :</b> Hot weather can lead to increased oil production and clogged pores.</p>
        <p><b>Prevention :</b> Cleanse your skin regularly and use oil-free products.</p>
        <p><b>Solution :</b> Treat breakouts with topical acne medications containing ingredients like salicylic acid.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Dry, Dehydrated Skin:</span>
        </h4 >
        <p><b>Problem :</b> Sun exposure and heat can leave skin feeling dry and tight.</p>
        <p><b>Prevention :</b> Stay hydrated and use a lightweight moisturizer.</p>
        <p><b>Solution :</b> Use hydrating skincare products containing ingredients like hyaluronic acid.</p>



        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Frizzy Hair:</span>
        </h4 >
        <p><b>Problem :</b> Humidity can cause hair to become frizzy and unruly.</p>
        <p><b>Prevention :</b> Use anti-frizz hair products and avoid heat styling.</p>
        <p><b>Solution :</b> Apply a nourishing hair mask to hydrate and tame frizz.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Sun Damage and Premature Aging:</span>
        </h4 >
        <p><b>Problem :</b> Prolonged sun exposure can lead to wrinkles and age spots.</p>
        <p><b>Prevention :</b> Wear sunscreen and protective clothing.</p>
        <p><b>Solution :</b> Use anti-aging skincare products containing ingredients like retinol.</p>

       

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Chlorine Damage:</span>
        </h4 >
        <p><b>Problem :</b> Chlorine can strip hair and skin of natural oils.</p>
        <p><b>Prevention :</b> Rinse off after swimming and wear a swim cap.</p>
        <p><b>Solution :</b> Use clarifying shampoos and moisturizing treatments.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Bug Bites and Stings:</span>
        </h4 >
        <p><b>Problem :</b> Insects are more prevalent in summer, leading to bug bites.</p>
        <p><b>Prevention :</b> Use insect repellent and wear protective clothing.</p>
        <p><b>Solution :</b> Treat bites with over-the-counter remedies like hydrocortisone cream.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Excessive Sweating:</span>
        </h4 >
        <p><b>Problem :</b> Hot weather can cause excessive sweating.</p>
        <p><b>Prevention :</b> Wear breathable clothing and use antiperspirant.</p>
        <p><b>Solution :</b> Shower regularly and use sweat-absorbing products.</p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "#fecb3e" }}>Fungal Infections:</span>
        </h4 >
        <p><b>Problem :</b> Warm, moist conditions can lead to fungal infections.</p>
        <p><b>Prevention :</b> Keep skin clean and dry, especially in prone areas.</p>
        <p><b>Solution :</b> Treat with antifungal creams and practice good hygiene.</p>


      </div>

    </>
  )
}

export default Top_10_Common_Summer_Skin_and_Hair_Problems