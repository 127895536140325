import React from 'react'
import { Link } from 'react-router-dom'


const Skin_Pigmentation = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Skin pigmentation</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Skin Pigmentation</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>

                        Skin pigmentation refers to the color of the skin, which is determined by the presence and distribution of melanin, a pigment produced by melanocytes (specialized cells in the skin). Melanin comes in two main forms: eumelanin, which is responsible for brown and black colors, and pheomelanin, which produces red and yellow hues.
                         </p>
                        <p style={{ textAlign: 'justify' }}>
                        The amount and type of melanin in the skin are influenced by various factors, including genetics, exposure to sunlight, and certain medical conditions. Skin pigmentation plays a crucial role in protecting the skin from the harmful effects of ultraviolet (UV) radiation. When the skin is exposed to sunlight, melanocytes produce more melanin to absorb and dissipate the UV radiation, helping to prevent damage to the DNA in skin cells.
                                                </p>
                        
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>There are different skin pigmentation conditions, including:</span>
                        </h4 >
                        




                    <p style={{ textAlign: 'justify' }}><b>Hyperpigmentation: </b>This refers to an excess of melanin in certain areas of the skin, leading to darker patches or spots. Common causes include sun exposure, inflammation, hormonal changes (such as during pregnancy or with the use of birth control pills), and certain medications.</p>
                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='skin-pigmentation.png' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Hypopigmentation: </b>This is a decrease in the production of melanin, resulting in lighter or white patches on the skin. Conditions such as vitiligo, albinism, and certain fungal infections can cause hypopigmentation.</p>
                <p style={{ textAlign: 'justify' }}><b>Melasma: </b>This is a type of hyperpigmentation characterized by brown or gray-brown patches, often occurring on the face. It is commonly associated with hormonal changes, such as those during pregnancy or while taking hormonal contraceptives.</p>
                <p style={{ textAlign: 'justify' }}><b>Freckles: </b>Freckles are small, concentrated areas of increased melanin production, usually triggered by sun exposure. They are more common in individuals with fair skin.</p>
                <p style={{ textAlign: 'justify' }}><b>Sunspots or age spots: </b>These are dark spots that develop on sun-exposed areas of the skin due to cumulative sun exposure over time.</p>
                <p style={{ textAlign: 'justify' }}>It's essential to protect the skin from excessive sun exposure to maintain healthy pigmentation and reduce the risk of skin damage and conditions related to pigmentation. Regular use of sunscreen and protective clothing can help prevent unwanted changes in skin pigmentation.</p>
               </div>

        </>
    )
}

export default Skin_Pigmentation