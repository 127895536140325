import React from 'react'
import { Link } from 'react-router-dom'


const Laser_Hair_Removal = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "#1f1f81", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-5" >
                        <h1 style={{ textTransform: 'uppercase', marginTop: '30px' }}>Laser Hair Removal</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center', marginBottom: '50px' }}>
                        <li ><Link to='/Home' style={{ color: 'whitesmoke', textDecoration: 'none', }}>Home</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#1f1f81" }}>Laser Hair Removal</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to Netis Skin and Hair Clinic, where we offer the latest advancements in hair removal technology. Under the expert care of Dr. Sai Prasanth Neti, our clinic provides state-of-the-art Laser Hair Removal treatments for a long-lasting solution to unwanted hair.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "#fecb3e" }}>Why Choose Netis Skin and Hair Clinic for Laser Hair Removal?</span>
                        </h4 >

                        <p style={{ textAlign: 'justify' }}><b>Dermatological Excellence: </b>Dr. Sai Prasanth Neti is a distinguished dermatologist known for his expertise in advanced skincare. With years of experience and a deep understanding of dermatological science, he leads our team in providing the highest level of care.</p>
                        <p style={{ textAlign: 'justify' }}><b>Customized Treatment Plans: </b>We understand that each individual's hair and skin type is unique. Our personalized treatment plans are tailored to address your specific concerns, ensuring optimal results.</p>

                    </div>
                    <div className="col-lg-5 servicesimg" style={{ textAlign: 'center' }} >
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='benefits-of-laser-hair-removal-on-face.jpeg' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}><b>Cutting-Edge Technology: </b>At Netis Skin and Hair Clinic, we stay at the forefront of dermatological advancements. Our Laser Hair Removal treatments utilize state-of-the-art technology for safe, effective, and comfortable procedures.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>About Laser Hair Removal</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Laser Hair Removal is a non-invasive procedure that uses concentrated light to target hair follicles. The heat from the laser damages the hair follicles, inhibiting future hair growth. This leads to long-lasting hair reduction, providing a smooth and hair-free complexion.
                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Key Benefits of Laser Hair Removal:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}><b>Precision: </b>Targets hair follicles without affecting the surrounding skin, ensuring precise and effective hair removal.</p>
                <p style={{ textAlign: 'justify' }}><b>Long-Lasting Results: </b>Enjoy long-term hair reduction, significantly reducing the need for regular shaving or waxing.</p>
                <p style={{ textAlign: 'justify' }}><b>Suitable for Various Areas: </b>Laser Hair Removal can be used on multiple areas of the body, including the face, arms, legs, bikini line, and more.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "#fecb3e" }}>Embark on Your Journey to Smooth, Hair-Free Skin</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}>At Netis Skin and Hair Clinic, we understand the impact that unwanted hair can have on your confidence and well-being. With Dr. Sai Prasanth Neti's expertise, cutting-edge technology, and personalized approach, we are dedicated to helping you achieve the smooth, hair-free skin you've always desired.</p>
                <p style={{ textAlign: 'justify' }}>Begin your journey towards a hair-free future. Schedule your consultation today, and let us guide you towards the silky-smooth skin you deserve.</p>


            </div>

        </>
    )
}

export default Laser_Hair_Removal